import { app as api } from '@ekuaibao/whispered'
import moment from 'moment'
import { CHANGJIEPayRefreshFilter, SOUCHEPayFilter } from '@ekuaibao/lib/lib/lib-util'
import { payingTableService } from '../service'
import { isString, isObject } from '@ekuaibao/helpers'

// @i18n-ignore
const defaultScenes = () => ({
  expense: i18n.get('报销单'),
  loan: i18n.get('借款单'),
  requisition: i18n.get('申请单'),
})
function getScenes(prefix, type, value) {
  return {
    text: defaultScenes()[type],
    sceneIndex: type,
    filters: [
      {
        key: `${prefix}form.specificationId.originalId.specificationGroupId`,
        value: [value],
        includeDep: false,
      },
    ],
    condition: 'or',
  }
}
export function getDefaultScenes(prefix, scenes, specificationGroup) {
  if (!specificationGroup) return []
  let newPrefix = prefix ? prefix + '.' : ''
  let defaultScenses = []
  scenes.map(item => {
    let groupIds = specificationGroup.filter(o => o.id.indexOf(item) > -1)
    if (groupIds.length > 0) {
      defaultScenses.push(getScenes(newPrefix, item, groupIds[0].id))
    }
  })
  return defaultScenses
}
export function getBaseCol() {
  return [
    {
      title: i18n.get('审批状态'),
      dataIndex: 'flowId.state',
      key: 'flowId.state',
      render: status => {
        const { stateFormat } = api.require('@lib/expenseMangerUtils')
        return stateFormat(status)
      },
    },
    {
      title: i18n.get('审批节点'),
      dataIndex: 'nodeName',
      key: 'nodeName',
    },
    {
      title: i18n.get('风险标识'),
      dataIndex: 'flowId.calcRiskWarning.status',
      key: 'flowId.calcRiskWarning.status',
    },
    {
      title: i18n.get('单据模板'),
      dataIndex: 'flowId.form.specificationId.name',
      key: 'flowId.form.specificationId.name',
    },
    { title: i18n.get('单号'), dataIndex: 'flowId.form.code', key: 'flowId.form.code' },
    { title: i18n.get('标题'), dataIndex: 'flowId.form.title', key: 'flowId.form.title' },
    {
      title: i18n.get('提交人'),
      dataIndex: 'flowId.form.submitterId.name',
      key: 'flowId.form.submitterId.name',
    },
    {
      title: i18n.get('提交日期'),
      dataIndex: 'flowId.form.submitDate',
      key: 'flowId.form.submitDate',
      render: submitDate => {
        return moment(submitDate).format('YYYY/MM/DD')
      },
    },
  ]
}
export function formatNum(number, index = 4) {
  let k = Math.floor(number.length / index)
  let arr = []
  for (let i = 0; i < k + 1; i++) {
    arr.push(number.slice(index * i, index * (i + 1)))
  }
  return arr.join(' ')
}

export function CHANGJIEPayV2RefreshFilter(item) {
  const { channel, state } = item
  return channel === 'CHANPAYV2' && state !== 'PENDING'
}

export function SOUCHEV2PayFilter(item) {
  const { channel } = item
  return channel === 'SOUCHEV2'
}

export function refreshBtnEnableFilter(item) {
  return (
    SOUCHEPayFilter(item) ||
    SOUCHEV2PayFilter(item) ||
    CHANGJIEPayRefreshFilter(item) ||
    CHANGJIEPayV2RefreshFilter(item)
  )
}

export const PayStatus = () => ({
  PROCESSED: i18n.get('支付中'),
  PENDING: i18n.get('支付中'),
  FAILED: i18n.get('支付失败'),
  FAILURE: i18n.get('支付失败'),
  SUCCESS: i18n.get('支付成功'),
  REVIEWING: i18n.get('复核中')
})

// type: 是批次(paymentBatch)还是批次中的某一条单据(payment)
export const PaymentBatchActionMap = (line, type, propsValue) => ({
  CONFIRM: {
    className: 'agree mr-12',
    onClick: () => payingTableService.confirmPayment(line, type),
    label: i18n.get('确认支付'),
  },
  CANCEL: {
    className: 'color-red',
    onClick: () => payingTableService.cancelPayment(line, type),
    label: i18n.get('取消支付'),
  },
  TOOFFLINE: {
    className: 'agree mr-12',
    onClick: () => payingTableService.changePaymentOffline(line, type),
    label: i18n.get('切换线下支付'),
  },
  AGREE: {
    className: 'agree mr-12',
    onClick: () => payingTableService.agreePaymentReview(line, type, propsValue),
    label: i18n.get('同意支付'),
  },
  REJECT: {
    className: 'agree mr-12',
    onClick: () => payingTableService.rejectPaymentReview(line, type, propsValue),
    label: i18n.get('驳回'),
  },
  TEMPLATE: {
    className: 'color-blue mr-16',
    onClick: _ => api.open('@audit:TransferVouchersSelectModal', { batchId: line.id }),
    label: i18n.get('下载转账文件'),
  }
})

export const PaymentActionMap = (line, type) => ({
  CONFIRM: {
    className: 'agree mr-12',
    onClick: () => payingTableService.confirmPayment(line, type),
    label: i18n.get('确认支付'),
  },
  CANCEL: {
    className: 'color-red',
    onClick: () => payingTableService.cancelPayment(line, type),
    label: i18n.get('取消支付'),
  },
  TOOFFLINE: {
    className: 'agree mr-12',
    onClick: () => payingTableService.changePaymentOffline(line, type),
    label: i18n.get('切换线下支付'),
  },
  REPAY: {
    className: 'agree mr-12',
    onClick: () => {
      return payingTableService.payPlanRepay(line, type)
    },
    label: i18n.get('重新支付'),
  },
  SETSUCCESS: {
    className: 'agree mr-12',
    onClick: () => {
      return payingTableService.checkPaySuccess(line, type)
    },
    label: i18n.get('手动确认成功'),
  },
  SETFAILED: {
    className: 'agree mr-12',
    onClick: () => {
      return payingTableService.checkPayFailed(line, type)
    },
    label: i18n.get('手动确认失败'),
  },
  LOGVIEW: {
    className: 'agree mr-12',
    onClick: () => {
      return payingTableService.payLogView(line, type)
    },
    label: i18n.get('支付日志'),
  },
  MULTIREPAY: {
    className: 'agree mr-12',
    onClick: () => {
      return payingTableService.payPlanRepay(line, type);
    },
    label: i18n.get('批量重新支付'),
  },
})

export function fnFilterMapping(mappingData, invoiceReviewPower) {
  const mapping = mappingData ?? { all: [] }
  if (!invoiceReviewPower) {
    const filterAll = mapping.all.filter(oo => oo !== 'reviewStatus')
    return { ...mapping, all: filterAll }
  } else {
    return mapping
  }
}

export function isOPG() {
  return location.origin.includes('opg.cn')
}

/**
 * 处理文件类型
 */
export const fetchBlobUrl = async ({
  url,
  fileName = null,
  type = 'application/pdf',
  method = 'get',
}) => {
  const res = await fetch(url, {
    method,
    responseType: 'arraybuffer',
  })
  const blobRes = res.status !== 200 ? await res.json() : await res.arrayBuffer()
  const name = fileName ? `attachment;filename=${fileName}` : 'attachment'
  // 生成 Blob 对象，设置 type 等信息
  const e = new Blob([blobRes], {
    'Content-Disposition': name,
    type,
  })
  // 将 Blob 对象转为 url
  const link = window.URL.createObjectURL(e)
  return link
}
//判断是否具有打印权限，如果设置了“仅允许制单人打印单据”，那只有制单人才有打印权限
export function isPrintShow(selectedRowsData) {
  if (!selectedRowsData || !Object.keys(selectedRowsData).length) {
    return true
  }
  const staffId = api.getState('@common')?.userinfo?.staff?.id
  let selectData = []
  Object.keys(selectedRowsData).forEach(key => {
    selectData.push(selectedRowsData[key])
  })
  let canPrint = true
  for (let value of selectData) {
    const flowId = value.hasOwnProperty('flowId') ? value.flowId : value
    const onlyOwnerPrint = flowId?.form?.onlyOwnerPrint
    const ownerAndApproverPrintNodeFlag = flowId?.form?.ownerAndApproverPrintNodeFlag
    const ownerId = isObject(flowId.ownerId) ? flowId.ownerId.id : flowId.ownerId
    //说明开启了限制审批人和制单人限制节点打印的配置
    if (ownerAndApproverPrintNodeFlag) {
      const state = flowId?.state
      const actions = flowId?.actions
      const myAction = actions?.[staffId]
      //可以打印的场景：1、审批结束后 2、myAcion包含'freeflow.print'
      canPrint = ['archived', 'paid'].includes(state) || myAction?.includes('freeflow.print')
    } else {
      //可以打印的场景：1、制单人永远可以打印 2、未开启允许制单人打印的配置
      canPrint = ownerId === staffId || !onlyOwnerPrint
    }
    //考虑批量情况下，批量情况下只要有一条可以打印，就跳出循环
    if (canPrint) {
      break;
    }
  }
  return canPrint
}

// 为支付计划维度提供的mapping，用来搜索法人实体的name和code
export const customColumnPropertyMapping4PayPlan = () => ({
  'form.E_system_paymentPlan_legalEntity': {
    name: 'E_system_paymentPlan_legalEntity',
    dataType: {
      entity: 'basedata.Dimension.法人实体',
      type: 'ref'
    }
  }
})

export function convertArray(ids) {
  if(Array.isArray(ids)) return ids
  if(isString(ids)) return ids?.split(',')
  return []
}

export const fnGetPayAmount = data => {
  const standardAmount = {
    key:'standard',
    label: "本位币",
    standard: data?.standard, // 本位币金额
    symbol: data?.standardSymbol, // 本位币符号
    strCode: data?.standardStrCode, // 本位币币种
    numCode: data?.standardNumCode, // 本位币编号
  }
  const foreignAmount = {
    key:'foreign',
    label: "原币",
    standard: data?.foreign, // 原币金额
    symbol: data?.foreignSymbol, // 原币符号
    strCode: data?.foreignStrCode, // 原币币种
    numCode: data?.foreignNumCode, // 原币编号
  }
  const amountList = [ foreignAmount, standardAmount ]
  return { standardAmount, foreignAmount, amountList }
}