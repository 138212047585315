import key from './key'
import { Fetch, Resource } from '@ekuaibao/fetch'

const backlogs = new Resource('/api/flow/v2/backlogs')
const receive = new Resource('/api/expresses/v2')
const flows = new Resource('/api/flow/v1/flows')
const flowPlans = new Resource('/api/v1/flow/plans')
const loanInfo = new Resource('/api/v1/loan/loanInfo')
const pay = new Resource('/api/pay/v1')
const express = new Resource('/api/expresses/v2')
const printRemind = new Resource('/api/v1/print/remind')
const carbonCopy = new Resource('/api/flow/v2/carbonCopy')
const riskWarningCount = new Resource('/api/flow/v2/riskwarning/count')
const backlogsApprove = new Resource('/api/backlogs/v2/approve')
const flowAction = new Resource('/api/flow/v2')
const riskFlowAction = new Resource('/api/flow/v2/riskwarning')
const paymentAccount = new Resource('/api/v2/pay/accounts/actives/filter')
const payerInfoConfig = new Resource('/api/pay/v2/accounts')
const dimension = new Resource('/api/flow/v2/referables')
const payPlanInfo = new Resource('/api/pay/v2/paymentPlans')
const paymentRecords = new Resource('/api/pay/v2/paymentRecords/mine')
const auditPaymentRecords = new Resource('/api/pay/v2/paymentRecords/queryByStaff')
const payChannel = new Resource('/api/pay/v2/channel')
const transferfile = new Resource('/api/pay/v2/transferfile')
const autographs = new Resource('/api/v1/basedata/autographs')
const bacthtipsapi = new Resource('/api/form/v2/requisition/flowsCloseRemind')
const receipt = new Resource('/api/pay/v1/receipt')
const logs = new Resource('/api/pay/v2/api/logs')
const words = new Resource('/api/v1/flow/commonStatements')
const configAction = new Resource('/api/pay/v1/plugin/processor')
const getCorporationGrant = new Resource('/api/pay/v2/api/corporationGrant')
const paymentBatches = new Resource('/api/pay/v2/paymentBatches')
const paymentManagement = new Resource('/api/pay/v2/paymentManagement')
const flowApprovalConfig = new Resource('/api/flow/v1/flowApprovalConfig')
import { app as api, app } from '@ekuaibao/whispered'

import { QuerySelect } from 'ekbc-query-builder'
import parseQuery2Select, {
  parseQuery2SelectNoPage,
  parseCommonSearchText,
} from '@ekuaibao/lib/lib/parseQuery2Select'
import parseSelectUsePropertySet from '@ekuaibao/lib/lib/parseSelectUsePropertySet'
import { postFlowAction } from '@ekuaibao/lib/lib/PostEventToIFrame'
import { convertArray } from './util/Utils'

function getPropertySet() {
  return api
    .dataLoader('@common.globalFields')
    .load()
    .then(resp => resp.data)
}

export function printRemindAction(keys) {
  if (!(keys instanceof Array)) {
    keys = [keys]
  }

  const flowIds = { flowIds: keys }
  return {
    type: key.PRINT_REMIND,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => printRemind.PUT('/[flowIds]', flowIds, { messageCode }))
      .then(res => {
        postFlowAction({ name: 'freeflow:remind', flowIds })
        return res
      })
      .catch(e => Promise.reject(e)),
  }
}

//后端获取动态添加的支付渠道
export function getDynamicChannel() {
  return {
    type: key.GET_DYNAMIC_CHANNEL,
    payload: payChannel.GET('/dict'),
  }
}
//获取连续审批配置
export function getFlowApprovalConfig() {
  return {
    type: key.GET_APPROVAL_CONFIG,
    payload: flowApprovalConfig.GET('/getConfig'),
  }
}
//修改连续审批配置
export function setApprovalConfig(isCheck) {
  return {
    type: key.SET_APPROVAL_CONFIG,
    payload: flowApprovalConfig.POST('/add', { continuousApproval: isCheck }),
  }
}

//同意代办流程,包括单个和批量
export function agreeBackLog(data, done) {
  let params = { ...data, name: 'freeflow.agree' }
  const { isSingle } = data
  const idType = isSingle ? '/$id' : '/[ids]'
  return {
    type: key.AGREE_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST(idType, params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.agree' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

//批量收单
export function receiveBackLogAction(params, done) {
  return {
    type: key.RECEIVE_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return receive.POST('/receive', params, { messageCode })
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

//批量收单异常
export function receiveExceptionBackLogAction(params, done) {
  return {
    type: key.RECEIVE_EXCEPTION_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return receive.POST('/receiveException', params, { messageCode })
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

//批量取消收单异常
export function cancelReceiveExceptionBackLogAction(params, done) {
  return {
    type: key.CANCEL_RECEIVE_EXCEPTION_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return receive.POST('/cancelReceiveException', params, { messageCode })
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

//根据运单号获取收单列表
export function getReceiveBackLogByNum(number, done) {
  let params = { num: number }
  return {
    type: key.GET_RECEIVE_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return receive.GET('/$num/waitreceive', params, { messageCode })
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

//闪电收单
export function LightningReceiveBackLog(flowCode, number, done, hiddenMsg) {
  let params = { flowCode: flowCode }
  if (number) {
    params = { num: number, flowCode: flowCode }
  }
  return {
    type: key.LIGHTNING_RECEIVE,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return receive.POST('/quickreceive', params, { messageCode }, null, { hiddenMsg })
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
//单个审批流节点配置驳回设置
export function spRejectBackLog(data, done) {
  let params = { ...data }
  return {
    type: key.REJECT_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/approveListWithNode', params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.reject' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
//驳回代办流程
export function rejectBackLog(data, done) {
  let params = { ...data, name: 'freeflow.reject' }
  return {
    type: key.REJECT_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/[ids]', params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.reject' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
// 明细维度驳回
export function rejectBackLogByFlow(data, done) {
  let params = { ...data, name: 'freeflow.reject' }
  return {
    type: key.REJECT_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/paymentPlan/flow/[ids]', params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.reject' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
//单个审批流节点配置驳回设置明细维度驳回
export function spRejectBackLogByFlow(data, done) {
  let params = { ...data }
  return {
    type: key.REJECT_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/paymentPlan/flow/node', params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.reject' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
// 单据作废action
export function nullifyBackLog(data, done) {
  const params = { ...data, name: 'freeflow.nullify' }
  return {
    type: key.NULLIFY_BACKLOG,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/[ids]', params, { messageCode })
      })
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.nullify' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}
export function transferFlow(param) {
  let request = api
    .invoke('@vendor:dd:message:code')
    .then(messageCode => {
      return backlogs.POST('/[id]', { ...param, name: 'freeflow.addnode' }, { messageCode })
    })
    .then(res => {
      postFlowAction({ ...param, name: 'freeflow.addnode' })
      return res
    })
    .catch(e => Promise.reject(e))
  return {
    type: key.TRANSFER_FLOW,
    payload: request,
  }
}

//支付代办流程
export function payBackLog(data, sendMessage) {
  const _url = data.isUsePaymentPlan ? `/payments/plan/$channel` : `/payments/$channel`
  delete data.isUsePaymentPlan
  return {
    type: key.PAY_BACKLOG,
    payload: pay.POST(_url, data),
  }
}

//修改流程审批节点
export function setFlowPlanNode(data, done) {
  return {
    type: key.SET_FLOW_PLAN_NODE,
    payload: flows.POST('/$flowId', data),
    done,
  }
}

//获取审批记录
export function getBackLogListByAction(params, bySearch, append = false) {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  const { sorter } = params
  let field = 'updateTime'
  let order = 'DESC'
  if (sorter) {
    field = sorter.field
    order = sorter.order
  }

  let query = new QuerySelect()
    .filterBy(`type!="permit"`)
    .filterBy(`state.in("PROCESSED")`)
    .filterBy(`updateTime > ${params.startApproveTime}`)
    .filterBy(`updateTime < ${params.endApproveTime}`)
    .filterBy(params.filter)
    .orderBy(field, order)
    .limit(params.start, params.count).select(`
      flowId(
        ownerId(id,name),
        calcRiskWarning(...),
        form(
          specificationId(...),
          submitterId(...),
          ...
        ),
        ...
      ),
      ...`)
  params.formTypes && query.filterBy(`type == "${params.formTypes}"`)
  if (window.__PLANTFORM__ === 'MC') {
    query.filterBy(`crossCorp==true`)
  }
  const searchText = params.searchText
  if (searchText) {
    if (lang) {
      query.filterBy(
        `lower(flowId.form.title).contains(lower("${searchText}"))` +
          `||lower(flowId.form.code).contains(lower("${searchText}"))` +
          `||lower(flowId.form.submitterId.name).contains(lower("${searchText}"))`,
      )
    } else {
      query.filterBy(
        `flowId.form.title.contains("${searchText}")` +
          `||flowId.form.code.contains("${searchText}")` +
          `||flowId.form.submitterId.name.contains("${searchText}")`,
      )
    }
  }
  query = query.value()

  if (append && !bySearch) {
    return {
      type: key.APPEND_BACKLOG_LIST_BY_ACTION,
      payload: backlogs.POST('/search', query),
    }
  }
  return {
    type: key.GET_BACKLOG_LIST_BY_ACTION,
    payload: backlogs.POST('/search', query),
  }
}

//获取审批流程详情
export async function getFlowInfo(id, done, noCheckPermissions) {
  const { joinParams, asyncSelectEntity } = app.require('@lib/entity.join')
  const fetchMutilValue = app.require('@lib/mutil-staff-fetch')
  const { fetchAttachment, fetchInvoice } = app.require('@lib/attachment-fetch')

  const selectEntity = await asyncSelectEntity()
  let query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity}`).value()
  let params = {
    id,
    ...joinParams(),
  }
  if (noCheckPermissions) params.checkPermissions = false
  let request = flows
    .POST('/flowId/$id', query, params)
    .then(fetchAttachment)
    .then(fetchInvoice)
    .then(fetchMutilValue)
    .then(resp => resp.value)
  return {
    type: key.GET_BACKLOG_INFO,
    payload: request,
    done,
  }
}

//获取代办详情
export async function getBackLogInfo({ id, hiddenMsg }) {
  const { joinParams, asyncSelectEntity } = app.require('@lib/entity.join')
  const fetchMutilValue = app.require('@lib/mutil-staff-fetch')
  const { fetchAttachment, fetchInvoice } = app.require('@lib/attachment-fetch')

  const selectEntity = await asyncSelectEntity('flowId')
  let query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity}`).value()

  let params = joinParams('flowId.')
  let keys = Object.keys(params)
  let lastKey = keys[keys.length - 1]
  let lastKeyIdx = Number.parseInt(lastKey.split('$')[1])
  params[`join$${lastKeyIdx + 1}`] = 'id,countDownDuration,/flow/v2/backlogs/duration'

  return {
    type: key.GET_BACKLOG_INFO,
    payload: backlogs
      .POST('/id', query, params, null, { hiddenLoading: true, hiddenMsg })
      .then(res => fetchAttachment(res, 'flowId'))
      .then(res => fetchInvoice(res, 'flowId'))
      .then(res => fetchMutilValue(res, 'flowId')),
  }
}

//根据ids获取代办详情列表
export async function getBackLogInfoList(ids) {
  const { joinParams, asyncSelectEntity } = app.require('@lib/entity.join')

  const selectEntity = await asyncSelectEntity('flowId')
  let filter = ids.map(id => `id=="${id}"`).join('||')
  let query = new QuerySelect().filterBy(filter).select(`${selectEntity}`).value()

  let params = joinParams('flowId.')
  let keys = Object.keys(params)
  let lastKey = keys[keys.length - 1]
  let lastKeyIdx = Number.parseInt(lastKey.split('$')[1])
  params[`join$${lastKeyIdx + 1}`] = 'id,countDownDuration,/flow/v2/backlogs/duration'
  return {
    type: key.GET_BACKLOG_INFO,
    payload: backlogs.POST('/ids', query, params),
  }
}

//#region 借款 action

//穿透查询,根据借款人ID和当前单据ID查询该借款人所有借款包列表
export function getLoanPackageListByID(params, done) {
  return {
    type: key.GET_LOAN_PACKAGE_LIST_BY_ID,
    payload: loanInfo.GET('/byOwnerIdAndLoanInfoId/$ownerId', params),
    done,
  }
}

//#endregion

//search
export function searchBackLogs(params) {
  let query = new QuerySelect() // .asc('payment.lastPayment.state')
    .filterBy(`(type == "${params.type}")`)
    .filterBy(`(state == "${params.state}")`)
    .filterBy(params.filters)
    .limit(params.start, params.count)
    .desc('isUrgent').select(`
      flowId(
        ownerId(id,name),
        form(
          loanDepartment(...),
          expenseDepartment(...),
          submitterId(id,name,code,email,cellphone,note),
          payeeId(...),
          specificationId(originalId(...),...),
          ...
        ),
        ...
      ),
      ...`)
  if (params.order) {
    let { order, value } = params.order
    query.orderBy(value, order)
  } else {
    query.desc('flowId.form.expenseDate')
  }

  query = query.value()
  return {
    type: key.SEARCH_BACKLOGS,
    payload: backlogs.POST('/search', query),
    state: params.state,
    billType: params.type,
  }
}

//待支付 支付失败  支付成功
export function searchPayPlanInfo(params, state) {
  return payPlanInfo.POST('/byState/$READY/mine', params, state)
}

//支付记录
export function searchPayPlanRecordInfo(q, params, from) {
  const query = new QuerySelect(q)
  if (params.filterTimes) {
    params.filterTimes.forEach(filter => {
      query.filterBy(`${filter.field} > ${filter.value[0]}`)
      query.filterBy(`${filter.field} < ${filter.value[1]}`)
    })
  }
  if (params.state) {
    query.filterBy(
      //@i18n-ignore
      `form.E_system_paymentRecord_支付结果.in("${
        Array.isArray(params.state) ? params.state.join('","') : params.state //@i18n-ignore
      }")`,
    )
  }
  if (params.order) {
    const order = params.order
    query.orderBy('form.' + order.field.replace('dataLink.', ''), order.order)
  }
  if (params.page) {
    const page = params.page
    query.limit((page.current - 1) * page.pageSize, page.pageSize)
  }
  if (params.searchText) {
    query.filterBy(
      `form.E_system_paymentRecord_编号.contains("${params.searchText || ''}")` + //@i18n-ignore
        `|| form.E_system_paymentRecord_支付批次号.contains("${params.searchText || ''}")`, //@i18n-ignore
    )
  }
  return from === 'auditPayment' ? auditPaymentRecords.POST(query.value()) : paymentRecords.POST(query.value())
}

//支付中
export function searchPayPlanProcessingInfo(params, state) {
  const query = parseQuery2Select().limit(params.limit.start, params.limit.count).value()
  return payPlanInfo.POST('/processing/mine', query, state)
}

export function getDataLinkEntities(params) {
  return {
    type: key.GET_DATALINK_ENTITIES,
    id: params.id,
    payload: payPlanInfo.POST('/dataLinkEntities', params),
  }
}

//电子回单
export function getReceiptDataLinkEntities() {
  return {
    type: key.GET_RECEIPT_DATALINK_ENTITIES,
    payload: receipt.POST('/dataLinkEntities'),
  }
}

export function searchReceipt(params, state) {
  return receipt.POST('/search', params, state)
}

export function searchBackLogsCore(params, scene, dimensionItems, showPermit) {
  const { joinMoney } = app.require('@lib/entity.join')
  const param = joinMoney('flowId.')
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  return getPropertySet()
    .then(propertySet => {
      const query = parseQuery2Select(params, undefined, undefined, lang)
      const content = parseSelectUsePropertySet(propertySet)
      const { sceneFiltersQuery, qs } = handleScence(scene, dimensionItems)
      query
        .desc('isUrgent')
        // .asc('payment.lastPayment.state')
        .asc('updateTime')
        .select(
          `flowId(flowRulePerformLogs(...),ownerId(id,name),plan(...),calcRiskWarning(...),form(${content},...),...),...`,
        )
        .filterBy(sceneFiltersQuery)
      if (params.sending) {
        query.filterBy(`(state.in("SENDING"))`)
      }
      if (!showPermit) {
        query.filterBy(`(type != "permit")`)
      }
      if (window.__PLANTFORM__ === 'MC') {
        query.filterBy(`crossCorp==true`)
      }
      if (params.partialPayState) {
        query.filterBy(`!flowId.form.partialPayState.isNull()`)
      }
      return { queryString: query.value(), qs }
    })
    .then(data => {
      return backlogs.POST('/search', data.queryString, { ...data.qs, ...param })
    })
}

export function searchPayPlanBackLogs(params, scene, dimensionItems, showPermit) {
  return getPropertySet()
    .then(propertySet => {
      const query = parseQuery2Select({ ...params, searchText: null })
      const content = parseSelectUsePropertySet(propertySet)
      const { sceneFiltersQuery, qs } = handleScence(scene, dimensionItems)
      query
        .select(`flowId(ownerId(id,name),plan(...),form(${content},...),...),payeeId(...),...`)
        .select(
          `form(E_system_paymentPlan_提交人(...),
          E_system_paymentPlan_收款信息(...),
          E_system_paymentPlan_legalEntity(...),
          E_system_paymentPlan_付款信息(...),...),...`,
        )
        .filterBy(sceneFiltersQuery)
      if (params.searchText) {
        parseCommonSearchText(params.searchText, [
          'payeeId.accountName',
          'form.E_system_paymentPlan_支付概要',
          'form.E_system_paymentPlan_提交人.name',
        ])(query)
      }
      if (params.sending) {
        query.filterBy(`(flowId.state.in("SENDING"))`)
      }
      if (!showPermit) {
        query.filterBy(`(flowId.formType != "permit")`)
      }
      if (window.__PLANTFORM__ === 'MC') {
        query.filterBy(`crossCorp==true`)
      }
      if (params.partialPayState) {
        query.filterBy(`!flowId.form.partialPayState.isNull()`)
      }
      return { queryString: query.value(), qs }
    })
    .then(data => {
      return payPlanInfo.POST('/backlog/search', data.queryString, { ...data.qs })
    })
}

export function getBackLogByFlowId(flowId) {
  return backlogs
    .GET('/current/$flowId', { flowId })
    .then(result => api.invokeService('@audit:get:backlog-info', result))
}

// 获取明细维度的全局字段（单据和字符计划的）
export function getPayPlanFields() {
  return payPlanInfo.GET('/list/fields/$path', { path: 'system_paymentPlan' })
}

function getBacklogSearchParams(params, scene, dimensionItems, needSelect = true) {
  return getPropertySet().then(propertySet => {
    const query = parseQuery2SelectNoPage(params)
    const content = parseSelectUsePropertySet(propertySet)
    const { sceneFiltersQuery, qs } = handleScence(scene, dimensionItems)
    if (needSelect) {
      query.select(`flowId(ownerId(id,name),form(${content},...),...),...`)
    }
    query.filterBy(sceneFiltersQuery).filterBy(`(type != "permit")`)
    if (params.sending) {
      query.filterBy(`(state.in("SENDING"))`)
    }
    if (params.partialPayState) {
      query.filterBy(`!flowId.form.partialPayState.isNull()`)
    }
    return { queryString: query.value(), qs }
  })
}

export function searchBackLogsCalcNoPage(params, scene, dimensionItems, legalEntityCurrencyPower) {
  return getBacklogSearchParams(params, scene, dimensionItems).then(data =>
    backlogs.POST(
      legalEntityCurrencyPower ? '/search/legalEntity/calc' : '/search/calc',
      data.queryString,
      data.qs,
    ),
  )
}

export function searchBacklogsSimple(params, scene, dimensionItems, legalEntityCurrencyPower) {
  return getBacklogSearchParams(params, scene, dimensionItems, false).then(data =>
    backlogs.POST(
      legalEntityCurrencyPower ? '/simple/search/legalEntity/calc' : '/simple/search/calc',
      data.queryString,
      data.qs,
    ),
  )
}

export function searchApprovedFlowsCore(paramsData, scene, dimensionItems) {
  const params = paramsData ?? {}
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  return getPropertySet()
    .then(propertySet => {
      const {
        otherFilters,
        needOrderByCode = true,
        APPROVED_CURRENT_NODE_STAFF,
        ...others
      } = params
      const query = parseQuery2Select(others, undefined, 'flow', lang)
      const content = parseSelectUsePropertySet(propertySet)
      const { sceneFiltersQuery, qs = {} } = handleScence(scene, dimensionItems, 'flow')
      if (needOrderByCode) {
        query.desc('form.code')
      }
      query
        .select(`ownerId(id,name),calcRiskWarning(...),form(${content},...),...`)
        .filterBy(`(formType != "permit")`)
        .filterBy(sceneFiltersQuery)
      if (otherFilters?.length) {
        otherFilters?.forEach(filter => {
          query.filterBy(filter)
        })
      }
      if (APPROVED_CURRENT_NODE_STAFF) {
        qs.join = 'id,nodeState,/flow/v2/backlogs/state'
      }
      return { queryString: query.value(), qs }
    })
    .then(data => flows.POST('/approved', data.queryString, data.qs))
}

function getApprovedParam(params, scene, dimensionItems, needSelect = true) {
  return getPropertySet().then(propertySet => {
    const query = parseQuery2SelectNoPage(params, undefined, 'flow')
    const content = parseSelectUsePropertySet(propertySet)
    const { sceneFiltersQuery, qs } = handleScence(scene, dimensionItems, 'flow')
    if (needSelect) {
      query.select(`ownerId(id,name),form(${content},...),...`)
    }
    query.filterBy(sceneFiltersQuery).filterBy(`(formType != "permit")`)
    return { queryString: query.value(), qs }
  })
}

export function searchApprovedFlowsCalcNoPage(
  params,
  scene,
  dimensionItems,
  legalEntityCurrencyPower,
) {
  return getApprovedParam(params, scene, dimensionItems).then(data =>
    flows.POST(
      legalEntityCurrencyPower ? '/approved/legalEntity/calc' : '/approved/calc',
      data.queryString,
      data.qs,
    ),
  )
}

export function searchApprovedFlowsSimpleCalcNoPage(
  params,
  scene,
  dimensionItems,
  legalEntityCurrencyPower,
) {
  return getApprovedParam(params, scene, dimensionItems, false).then(data =>
    flows.POST(
      legalEntityCurrencyPower ? '/approved/simple/legalEntity/calc' : '/approved/simple/calc',
      data.queryString,
      data.qs,
    ),
  )
}

export function getBacklogFlowplan({ flowId }) {
  return flowPlans.GET('/$id', {
    id: flowId.id,
    join:
      'nodes.counterSigners.signerId,signerId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$1:
      'nodes.counterSignersCandidate.signerId,signerId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$2:
      'nodes.approverId,approverId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$3:
      'submitterId,submitterId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$4:
      'ownerId,ownerId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
  })
}

export function getVersionsFlowplan({ planId }) {
  return flowPlans.GET('/versioned/$id', {
    id: planId,
    join: 'flowPlan.nodes.counterSigners.signerId,signerId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$1:
      'flowPlan.nodes.counterSignersCandidate.signerId,signerId,/v1/organization/staffs?select=id,name,code,avatar,email,cellphone,note',
    join$2: 'flowPlan.nodes.approverId,approverId,/v1/organization/staffs',
  })
}

export function getDims(params, done) {
  let query = new QuerySelect()
    .filterBy(`state.in("${params.state}")`)
    .filterBy(`type == "${params.type}"`)

  const p1 = backlogs.POST(
    '/search',
    query.reset('select').dims(`flowId.form.specificationId`).select('id,name').value(),
  )

  const p2 = backlogs.POST(
    '/search',
    query.reset('select').dims(`flowId.form.${params.type}Department`).select('id,name').value(),
  )

  const p3 = backlogs.POST(
    '/search',
    query.reset('select').dims(`flowId.form.payeeId`).select('id,bank').value(),
  )

  return {
    type: key.GET_DIMS,
    payload: Promise.all([p1, p2, p3]).then(data => {
      return {
        specList: data[0].items,
        deptList: data[1].items,
        bankList: data[2].items,
      }
    }),
    done,
  }
}

//查询短信提醒设置
export function getPayBindPhoneInfo(done) {
  return {
    type: key.GET_PAY_BIND_PHONE_INFO,
    payload: pay.GET('/options'),
    done,
  }
}

export function setPayBindPhone(data, done) {
  return {
    type: key.SET_PAY_BIND_IPHONE,
    payload: pay.PUT('/options/sms/cellphone', data),
    done,
  }
}

export function getPayCaptha(cellphone, ncpcData, done) {
  return {
    type: key.GET_PAY_PHONE_CAPTCHA,
    payload: pay.POST('/options/sms/identity', { cellphone, ...ncpcData }),
    done,
  }
}

export function getThirdCaptcha(cellphone, ncpcData, done) {
  return {
    type: key.GET_THIRD_PHONE_CAPTCHA,
    payload: Fetch.POST('/api/ekbmall/v1/third/captcha/$120202', null, {
      body: { cellphone, ...ncpcData },
    }),
    done,
  }
}

export function openPayMessage(data, done) {
  return {
    type: key.OPEN_PAY_MESSAGE,
    payload: pay.PUT('/options/sms/enable', data),
    done,
  }
}

//支付
export function confirmPayBath(data) {
  const params = { ...data }
  //为解决hotfixPRO-18462支付中连续点击造成的消息推送超过频次后能够继续支付，在catch中也走一遍支付，后续优化全部去掉messageCode
  return {
    type: key.CONFIRM_PAY,
    payload: pay.PUT('/$id/confirm', params, { messageCode: 'debug' }).then(res => {
      postFlowAction({ ...data, name: 'freeflow.confirmPayBatch' })
      return res
    }),
  }
}

export function confirmPayment(data) {
  const params = { ...data }
  //为解决hotfixPRO-18462支付中连续点击造成的消息推送超过频次后能够继续支付，在catch中也走一遍支付，后续优化全部去掉messageCode
  return {
    type: key.CONFIRM_PAYMENT,
    payload: pay.PUT('/payments/$id/confirm', params, { messageCode: 'debug' }).then(res => {
      postFlowAction({ ...data, name: 'freeflow.confirmPay' })
      return res
    }),
  }
}

export function cancelPayBatch(data, done) {
  const params = { ...data }
  return {
    type: key.CANCEL_PAY_BATCH,
    payload: pay.PUT('/$id/cancel', params).then(res => {
      postFlowAction({ ...data, name: 'freeflow.cancelPay' })
      return res
    }),
    done,
  }
}

export function cancelPayment(data, done) {
  const params = { ...data }
  return {
    type: key.CANCEL_PAY,
    payload: pay.PUT('/payments/$id/cancel', params).then(res => {
      postFlowAction({ ...data, name: 'freeflow.cancelPay' })
      return res
    }),
    done,
  }
}

export function cancelPaymentByBatch(data, done) {
  const params = { ...data }
  return {
    type: key.CANCEL_PAY,
    payload: pay.PUT('/cancel/byFlowId/$flowId/byBatchId/$batchId ', params).then(res => {
      postFlowAction({ ...data, name: 'freeflow.cancelPayBatch' })
      return res
    }),
    done,
  }
}

export function zeroPay(data, done) {
  return {
    type: key.ZERO_PAY,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => pay.POST('/payments/zeroPay', data, { messageCode }))
      .then(res => {
        postFlowAction({ ...data, name: 'freeflow.zeroPay' })
        return res
      })
      .catch(e => Promise.reject(e)),
    done,
  }
}

export function getPaymentBatch(param, done) {
  return {
    type: key.GET_PAYMENT_BATCH,
    // payload: pay.GET('/payments', param),
    payload: paymentBatches.POST('', param),
    done,
  }
}

export function getAuditSecondList(id) {
  return paymentBatches.GET('/$id/flows', { id })
}

export function getPaymentPayPlanList(id) {
  return paymentBatches.POST('/$id/paymentPlans', { id })
}

export function getAuditPayPlanList(params) {
  return paymentBatches.GET('/$batchId/flow/$flowId/payments', { ...params })
}

export function getPaymentRecord(data, done, append = false) {
  let params = {
    ...data,
    join: `accountId,accountCompany,/pay/v1/accounts`,
  }
  if (append) {
    return {
      type: key.APPEND_PAYMENT_RECORD,
      payload: pay.GET('/records/search', params),
      done,
    }
  }
  return {
    type: key.GET_PAYMENT_RECORD,
    payload: pay.GET('/records/search', params),
    done,
  }
}

export function updatePaymentState(data, done) {
  return {
    type: key.UPDATE_PAYMENT_STATE,
    payload: pay.PUT('/payments/$id', data),
    done,
  }
}

export function searchBackLogsCarbonCopy(params, scene, dimensionItems) {
  const filtersFixer = app.require('@lib/filtersFixer')

  return getPropertySet()
    .then(propertySet => {
      const query = parseQuery2Select(params)
      const content = parseSelectUsePropertySet(propertySet)
      const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ``
      query
        .select(`flowId(ownerId(id,name),calcRiskWarning(...),form(${content},...),...),...`)
        .filterBy(sceneFiltersQuery)
        .asc('updateTime')
        .filterBy(`(type != "permit")`)
      return query.value()
    })
    .then(queryString => carbonCopy.POST('/search', queryString))
}

export function markedReadSelect(ids) {
  return {
    type: key.MARKED_READ_SELECTED,
    payload: carbonCopy.PUT('/byFlowId/[ids]', { ids }),
  }
}

export function markedReadALL() {
  return {
    type: key.MARKED_READ_ALL,
    payload: carbonCopy.PUT('/setAlreadyReadAll'),
  }
}

export async function getCarbonCopyFlowInfoById({ id }) {
  const { joinParams, asyncSelectEntity } = app.require('@lib/entity.join')
  const fetchMutilValue = app.require('@lib/mutil-staff-fetch')
  const { fetchAttachment, fetchInvoice } = app.require('@lib/attachment-fetch')

  const selectEntity = await asyncSelectEntity('flowId')
  let query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity}`).value()

  let params = joinParams('flowId.')
  params = { ...params, id }
  return {
    type: key.GET_CARBON_COPY_FLOW_INFO_BY_ID,
    payload: carbonCopy
      .POST('/$id', query, params)
      .then(res => fetchAttachment(res, 'flowId'))
      .then(res => fetchInvoice(res, 'flowId'))
      .then(res => fetchMutilValue(res, 'flowId')),
  }
}

export async function getCarbonCopyFlowInfoByFlowId({ id }) {
  return {
    type: key.GET_CARBON_COPY_FLOW_INFO_BY_FLOW_ID,
    payload: carbonCopy.GET('/byFlowId/$id', { id }),
  }
}

export function searchCarbonCopyCalcNoPage(
  params,
  scene,
  dimensionItems,
  legalEntityCurrencyPower,
) {
  const filtersFixer = app.require('@lib/filtersFixer')

  return getPropertySet()
    .then(propertySet => {
      const query = parseQuery2SelectNoPage(params)
      const content = parseSelectUsePropertySet(propertySet)
      const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ''
      query
        .select(`flowId(ownerId(id,name),form(${content},...),...),...`)
        .filterBy(sceneFiltersQuery)
        .filterBy(`(type != "permit")`)
      return query.value()
    })
    .then(queryString =>
      carbonCopy.POST(
        legalEntityCurrencyPower ? '/search/legalEntity/calc' : '/search/calc',
        queryString,
      ),
    )
}

//寄送单据和跳过单据
export function sendExpress(data) {
  return api
    .invoke('@vendor:dd:message:code')
    .then(messageCode => express.POST('/send', data, { messageCode }))
    .catch(e => Promise.reject(e))
}

//补充运单号
export function supplementExpress(data) {
  return express.POST('/flowexpress', data)
}

//获取快递信息
export function getExpress(data) {
  return {
    type: key.GET_EXPRESS,
    expressId: data.expressId,
    payload: express
      .GET(`/track/$id/byflowid/$byflowid`, { id: data.expressId, byflowid: data.flowId })
      .catch(e => e),
  }
}

//获取单据的所有快递单号
export function getExpressNums(flowId) {
  return {
    type: key.GET_EXPRESS_NUMS,
    payload: express.GET(`/byflowid/$flowId`, { flowId }),
  }
}

//修改运单号
export function editExpressNum(data) {
  return express.PUT(`/flowexpress/revise`, data)
}

//补充运单号
export function supplementExpressNum(data) {
  return express.POST(`/flowexpress`, data)
}

//通过单据号找单据详情
export function getBillInfoByCode({ code }) {
  return getPropertySet()
    .then(propertySet => {
      const query = new QuerySelect()
      const content = parseSelectUsePropertySet(propertySet)
      query
        .select(`flowId(ownerId(id,name),form(${content},...),...),...`)
        .filterBy(`(type != "permit")`)
        .filterBy(`(flowId.form.code == "${code}")`)
        .filterBy(`(state.in("SENDING"))`)
      return { queryString: query.value() }
    })
    .then(data => {
      return backlogs.POST('/search', data.queryString)
    })
}

function handleScence(scene, dimensionItems, prefix) {
  const filtersFixer = app.require('@lib/filtersFixer')

  let sceneFiltersQuery = '',
    qs = undefined
  if (!scene) {
    return { sceneFiltersQuery, qs }
  }
  if (scene.scene === 'waitInvoice') {
    qs = { waitInvoice: true }
  } else {
    sceneFiltersQuery = filtersFixer(scene, prefix, dimensionItems)
  }
  return { sceneFiltersQuery, qs }
}

export function getSingleRiskWarning({ flowId }) {
  return riskWarningCount.GET('/$id', { id: flowId.id })
}

export function checkRiskWarning(param) {
  return riskWarningCount.GET('/[flowIds]', param)
}

export function batchApproveByIds(params, messageCode) {
  let { ids, ...action } = params
  ids = convertArray(ids)
  return backlogsApprove.POST('/ids', { backlogIds: ids, action }, { messageCode })
}

export function getAllRiskWarning(ids, fetchParams) {
  const query = parseQuery2Select(fetchParams)
  query.filterBy(`state.in("APPROVING")`).filterBy(`type != "permit"`)
  return riskWarningCount.POST(query.value())
}

export function batchApproveByConditions(params, messageCode) {
  const { fetchParams, ...others } = params
  const { scene, dimensionItems } = fetchParams
  const { sceneFiltersQuery } = handleScence(scene, dimensionItems)
  const query = parseQuery2SelectNoPage(fetchParams)
    .filterBy(sceneFiltersQuery)
    .filterBy(`(type != "permit")`)
  return backlogsApprove.POST('', { ...others, query: query.value() }, { messageCode })
}

export function checkApproveProgress(params) {
  return {
    type: key.CHECK_APPROVE_PROGRESS,
    payload: backlogsApprove.GET('/result', params, null, { hiddenLoading: true }),
  }
}

export function getBatchFlowConfig(params) {
  let { flowIds, ...others } = params
  flowIds = convertArray(flowIds)
  return flowAction.POST('/batchflowplan', { flowIds }, { ...others })
}

export function getBatchFlowConfigByConditions(params) {
  const { fetchParams, ...others } = params
  const { query, qs = {} } = getQueryByParam(fetchParams)
  return flowAction.POST('/batchflowplan/query', query.value(), { ...others, ...qs })
}

export function getRiskCountInBatchAgree(params) {
  let { flowIds, ...others } = params
  flowIds = convertArray(flowIds)
  return riskFlowAction.POST('/coalition/count/flowIds', { flowIds }, { ...others })
}

export function getRiskCountInBatchByConditions(params) {
  const { fetchParams, ...others } = params
  const { query, qs = {} } = getQueryByParam(fetchParams)
  return riskFlowAction.POST('/coalition/count', query.value(), { ...others, ...qs }, null, {
    hiddenLoading: true,
  })
}

function getQueryByParam(fetchParams) {
  const { scene, dimensionItems } = fetchParams
  const { sceneFiltersQuery, qs } = handleScence(scene, dimensionItems)
  const query = parseQuery2SelectNoPage(fetchParams)
    .filterBy(sceneFiltersQuery)
    .filterBy(`(type != "permit")`)
  return { query, qs }
}

export function getAccountsReceivableInfo(params) {
  return pay.POST('/payments/validCondition', params)
}

export function getPaymentAccount(params) {
  return paymentAccount.POST('', { ...params })
}

export function payAgin(params, messagecode) {
  return {
    type: key.REPAY,
    payload: pay.POST('/payments/$channel/repay', { ...params }, { messagecode }),
  }
}

export function postPayPlanRepay(params, messagecode) {
  return {
    type: key.PAYPLAN_REPAY,
    payload: pay.POST('/payments/plan/$channel/repay', { ...params }, { messagecode }),
  }
}

//获取转账模板
export function getTransferfileTemplate() {
  return {
    type: key.GET_TRANSFERFILE_TEMPLATE,
    payload: transferfile.GET('/template'),
  }
}
//获取摘要配置
export function getPayerInfoConfig(id) {
  return payerInfoConfig.GET('/getRemarkSetting/$id', { id })
}
//获取自定义文档树
export function getDimensionList(id) {
  return dimension.GET('/$id', { id })
}

export function updateImageSign(imageData) {
  return {
    type: key.UPDATE_IMAGE_SIGN,
    payload: autographs.PUT('/$id', imageData),
  }
}

export function saveImageSign({ imageId, use, lastHabit }) {
  return {
    type: key.SAVE_IMAGE_SIGN,
    payload: autographs.POST('', { imageId, use, lastHabit }),
  }
}

//处理批量提示
export function handleBatchTips(keys, data) {
  return bacthtipsapi.PUT('', { ids: keys, maxCount: 100 })
}

//获取支付计划
export function getPaymentPlan(ids) {
  return payPlanInfo.POST('/search', ids)
}

//获取支付计划(支持分页)
export function getPaymentPlanByPage(ids) {
  return payPlanInfo.POST('/searchByPage', ids)
}

//检查支付计划
export function checkPaymentPlan(data) {
  return payPlanInfo.POST('/check', data)
}

//保存临时数据，返回临时数据的临时码
export function saveTempDataForExport(dataList) {
  return payPlanInfo.POST('/saveTempDataForExport', dataList)
}

//保存临时数据，返回临时数据的临时码
export function exportPaymentPlan(params) {
  return payPlanInfo.GET('/exportPaymentPlan', params)
}

//支付计划风控查询
export function getValidCondition(params) {
  return pay.POST('/payments/plan/validCondition', params)
}

export function checkRemark(params) {
  return payPlanInfo.POST('/checkRemark', params)
}

// 重新支付查询是否需要展示代发类型配置项
export function getValidConditionForPayPlanRepay(params) {
  return pay.POST('/payments/plan/repay/validCondition', params)
}

export function getPayModels(channel, orderType) {
  return payChannel.GET('/getPayModels/$channel', { channel, orderType })
}

export function getTransactionType(channel, accountNo) {
  return payChannel.GET('/getTransactionCode/$channel', { channel, accountNo })
}

export function getPayLogs(params) {
  return logs.POST('/queryTrackLogs/$flowId', params)
}

export function getPayLogSearchItems(params) {
  return logs.GET('/getSearchItems/$flowId', params)
}

export function setPayState(params) {
  return pay.POST('/payments/manual/confirm/$channelType', params)
}

// 获取手工确认失败过的数据
export function getFailedFlow(flowIds) {
  const idStr = flowIds.join('","')
  const query = new QuerySelect()
    .filterBy(`type!="permit"`)
    .filterBy(`state.in("PAYING")`)
    .filterBy(`flowId.in("${idStr}")`)
    .value()
  return pay.POST('/payments/checkPayMarked', query)
}

export function setInBatchPaying(flag) {
  return {
    type: key.SET_IN_BATCH_PAYING,
    payload: Promise.resolve({ inBatchPaying: flag }),
  }
}
//获取审批常用语
export function getCommentWords(param) {
  return {
    type: key.GET_COMMENT_WORDS,
    payload: words.GET('/getInfo/$type', param),
  }
}
//新建审批常用语
export function newCommentWords(param) {
  return {
    type: key.NEW_COMMENT_WORDS,
    payload: words.POST('/create/$from', param),
  }
}
//编辑审批常用语
export function editCommentWords(param) {
  return {
    type: key.EDIT_COMMENT_WORDS,
    payload: words.PUT('/save/$from', param),
  }
}

// 获取支付计划维度支付金额
export function postPayMoneyByDetail(ids) {
  return pay.POST('/payments/plan/money', { ids }).then(result => result?.value)
}

// 获取民生银行用途列表
export function getMSPayPurpose(channel) {
  return pay.POST('/payments/$channel/getMSBankPurpose', { channel })
}

// 获取不同银行支付渠道的配置
export function getPaymethodSettingConfig(channel) {
  return payChannel.POST('/$channel/inputConfig', { channel })
}

// 根据后台接口获取配置数据值
export function getPayConfigInitData(params) {
  return configAction.POST('/$channel', { ...params })
}

// 获取退汇列表数据
export function getReceiptReexchange({ params, searchVal, manualMatch }) {
  const queryStringObj = params.byStaff ? { byStaff: true } : {}
  if (manualMatch) {
    queryStringObj.manualMatch = true
  }
  const query = new QuerySelect().limit(params.start, params.count)
  query.filterBy(`form.E_system_借贷标志==\"c\" || form.E_system_借贷标志==\"C\"`)
  if (searchVal) {
    query.filterBy(
      `form.E_system_单号_编号.contains("${searchVal}")` +
        `|| form.E_system_回单编号.contains("${searchVal}")`,
    )
  }
  return receipt.POST('/reexchange/searchByPage', query.value(), queryStringObj)
}

/**
 * 检查单据必填字段是否填写
 * @param params { flowIds : [] }
 */
export function checkFlowIsAllRequired(params) {
  return flowAction.POST('/check/[flowIds]', params)
}

// 获取支付计划列表数据、获取手动退汇列表数据
export function getPaymentPlans(params, searchVal, isHandMatch) {
  const queryStringObj = {}
  if (params.byStaff) {
    queryStringObj.byStaff = true
  }
  if (searchVal) {
    //  按提交人搜索：
    queryStringObj.staffName = searchVal
  }
  let query = new QuerySelect().limit(params.limit.start, params.limit.count)
  if (isHandMatch) {
    query.filterBy('form.E_system_paymentPlan_支付状态.in("REEXCHANGE","MANUAL_RECEIPT")')
  } else {
    query.filterBy(`form.E_system_paymentPlan_支付状态.in(\"SUCCESS\")`)
  }
  if (searchVal) {
    query.filterBy(
      `form.E_system_paymentPlan_编号.contains(\"${searchVal}\") || form.E_system_paymentPlan_支付概要.contains(\"${searchVal}\")`,
    )
  }
  return payPlanInfo.POST('/searchByPage', query.value(), queryStringObj)
}

// 保存选中支付计划
export function savePaymentPlans(ids) {
  return receipt.POST('/reexchange/addManual/[ids]', { ids })
}

// 删除手动退汇
export function removeManual(ids) {
  return receipt.POST('/reexchange/removeManual/[ids]', { ids })
}

// 上传退汇凭证
export function bindFile(id, params) {
  return receipt.POST(`/reexchange/bindFile/${id}`, params)
}

// 删除退汇凭证
export function unbindFile(id) {
  return receipt.POST(`/reexchange/unbindFile/${id}`)
}

export function queryCorporationGrantInfo(channelData, params) {
  const channel = channelData ?? ''
  return getCorporationGrant.POST('/query/$channel', { channel, ...params })
}

// 获取单据维度支付金额
export function postPayMoneyByFlow(ids) {
  return pay.POST('/payments/flow/money', { ids }).then(result => result?.value)
}

// 获取单据维度部分支付支付金额
export function postPartialMoneyByFlow(ids) {
  return pay.POST('/payments/flow/partial/money', { ids }).then(result => result?.value)
}

// 获取支付中支付计划支付金额
export function postPayingPaymentMoneyByFlow(ids) {
  return pay.POST('/payments/payment/money', { ids }).then(result => result?.value)
}

// 刷新支付按钮是否可以点击
export async function getRefreshBtnClickable() {
  const result = await paymentBatches.GET('/checkRefreshBtn')
  return result?.value
}

// 线下支付更新支付完成时间
export function setOfflineFinishedTime(avaliable) {
  return {
    type: key.OFFLINE_FINISHED_TIME_AVAILABLE,
    payload: Promise.resolve({ offlineFinishTimeAvailable: avaliable }),
  }
}

export function queryAllPaymentManagementSetting() {
  return paymentManagement.POST(
    '/setting/query',
    {},
    { join: `staffId,staffId,/v1/organization/staffs` },
  )
}

export function postInsertPaymentManagementSetting(data) {
  return paymentManagement.POST('/setting/insert', data)
}

export function postUpdatePaymentManagementSetting(data) {
  return paymentManagement.POST('/setting/update', data)
}

export function deletePaymentManagementSetting(id) {
  return paymentManagement.DELETE('/setting/delete/$id', { id })
}

export function postAllPaymentManagementBatch(data) {
  return paymentManagement.POST('/review/query/cashier', data, {
    join: `payerId,payerId,/v1/organization/staffs`,
    join$1: `ownerId,ownerId,/v1/organization/staffs`,
  })
}

export function postPaymentManagementBatch(data) {
  return paymentManagement.POST('/review/query', data, {
    join: `ownerId,ownerId,/v1/organization/staffs`,
    join$1: `payerId,payerId,/v1/organization/staffs`,
  })
}

export function postPaymentManagementApproval(data) {
  return paymentManagement.POST('/review/approval', data)
}

export function postPaymentManagementReviewRecord(data) {
  const joins = {
    join: `cashierId,cashierId,/v1/organization/staffs`,
    join$1: `payerAccountId,payerAccountId,/pay/v2/accounts`,
    join$2: `payeeAccountId,payeeAccountId,/pay/v2/accounts`,
  }
  return paymentManagement.POST('/review/queryLog', data, {
    ...joins,
  })
}

export function getAccountsAll(filter = { asPayer: true, start: 0, count: 600 }) {
  return payerInfoConfig.GET(
    `/all?filter=(asPayer == ${filter.asPayer})&start=${filter.start}&count=${filter.count}`,
  )
}