import React from 'react'
import { Icon, Tooltip } from 'antd'
import { isPrintShow } from './Utils'
import { app } from '@ekuaibao/whispered'

const getActionLabel = () => i18n.get('操作')
const getRiskLabel = () => i18n.get('风险标识')
export function createNodeNameColumn() {
  const EkbIcon = app.require('@elements/icon')
  return {
    title: i18n.get('审批节点'),
    filterType: 'text',
    dataIndex: 'nodeName',
    label: (
      <Tooltip placement="bottom" title={i18n.get('暂不支持导出，打印')}>
        <span style={{ color: '#333' }}>{i18n.get('审批节点')}</span>
        <EkbIcon name="EDico-help" />
      </Tooltip>
    ),
    className: 'fs-14',
    sorter: true,
  }
}

const getRiskState = () => [
  { label: i18n.get('有风险'), value: 'HAVE' },
  { label: i18n.get('无风险'), value: 'NONE' },
]

export function createNodeStaffColumn() {
  const EkbIcon = app.require('@elements/icon')

  return {
    key: 'nodeState.staffName',
    title: i18n.get('当前审批人'),
    filterType: 'text',
    dataIndex: 'nodeState.staffName',
    label: (
      <Tooltip placement="bottom" title={i18n.get('暂不支持打印')}>
        <span style={{ color: '#333' }}>{i18n.get('当前审批人')}</span>
        <EkbIcon name="EDico-help" />
      </Tooltip>
    ),
    className: 'fs-14',
    sorter: false,
  }
}

export function createRiskWarningColumn(hasFlowId) {
  const EkbIcon = app.require('@elements/icon')
  const Skeleton = app.require('@elements/Skeleton')
  const statusStr = hasFlowId ? 'flowId.calcRiskWarning.status' : 'calcRiskWarning.status'
  return {
    title: getRiskLabel(),
    filterType: 'list',
    dataIndex: statusStr,
    filterDataSource: getRiskState(),
    label: getRiskLabel(),
    key: statusStr,
    value: statusStr,
    render(text, line) {
      const hashDes = (
        <>
          <EkbIcon name="EDico-plaint-circle" style={{ color: '#fa8c16', marginRight: '5px' }} />
          <span>{i18n.get('有风险')}</span>
        </>
      )
      const noDes = (
        <>
          <EkbIcon name="EDico-check-circle" style={{ color: '#5ed73a', marginRight: '5px' }} />
          <span>{i18n.get('无风险')}</span>
        </>
      )
      const countDes = (
        <>
          <Skeleton />
        </>
      )
      if (line?.flowId) {
        if (line?.flowId?.calcRiskWarning) {
          if (line?.flowId?.calcRiskWarning?.status === 'HAVE') {
            return hashDes
          } else if (line?.flowId?.calcRiskWarning?.status === 'NONE') {
            return noDes
          }
        } else {
          return countDes
        }
      } else {
        if (line?.calcRiskWarning) {
          if (line?.calcRiskWarning?.status === 'HAVE') {
            return hashDes
          } else if (line?.calcRiskWarning?.status === 'NONE') {
            return noDes
          }
        } else {
          return countDes
        }
      }
    },
    className: 'fs-14',
    sorter: false,
  }
}

export function createActionColumn4Approve(bus) {
  return {
    title: getActionLabel(),
    width: 280,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      const nodes = line?.flowId?.plan?.nodes
      const taskId = line?.flowId?.plan?.taskId
      const node = nodes?.find(v => v.id === taskId) || {}
      const isForbidReject = node?.forbidRejectNode
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 3 /*'agree'*/, line)}>
            {i18n.get('同意')}
          </a>
          {isForbidReject ? (
            <a
              className="ant-dropdown-link mr-16"
              style={{ color: '#9E9E9E', textDecoration: 'none', cursor: 'not-allowed' }}>
              {i18n.get('驳回')}
            </a>
          ) : (
            <a
              className="ant-dropdown-link mr-16"
              onClick={e => bus.emit('table:row:action', 1 /*'reject'*/, line)}>
              {i18n.get('驳回')}
            </a>
          )}
          {
            isPrintShow({selectedRowsData: line}) && (
            <a
              className="ant-dropdown-link mr-16"
              onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
              {i18n.get('打印')}
            </a>
            )
          }
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 16 /*'receive_print'*/, line)}>
            {i18n.get('收到打印')}
          </a>
        </div>
      )
    },
  }
}

export function createActionColumn4Receive(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 12 /*'确认收单'*/, line)}>
            {i18n.get('确认收单')}
          </a>
          {
            isPrintShow({selectedRowsData: line}) && (
            <a
              className="ant-dropdown-link mr-16"
              onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
              {i18n.get('打印')}
            </a>
            )
          }
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 16 /*'receive_print'*/, line)}>
            {i18n.get('收到打印')}
          </a>
        </div>
      )
    },
  }
}

export function createActionColumn4ReceiveException(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 12 /*'确认收单'*/, line)}>
            {i18n.get('确认收单')}
          </a>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 21 /*'确认收单'*/, line)}>
            {i18n.get('取消收单异常')}
          </a>
        </div>
      )
    },
  }
}

export function createActionColumn4Approved(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
            {
              isPrintShow({selectedRowsData: line}) && (
              <a
                className="ant-dropdown-link mr-16"
                onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
                {i18n.get('打印')}
              </a>
              )
            }
        </div>
      )
    },
  }
}

export function createActionColumn4Pay(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      if (!line.flowId) return <div />
      let logs = (line.flowId && line.flowId.logs) || []
      let lastLog = logs[logs.length - 1] || {}
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          {lastLog.state === 'failure' ? (
            <Tooltip
              overlay={
                <div>
                  <p>
                    {i18n.get('失败原因')}
                    {i18n.get('：')}
                    {lastLog.attributes.paymentChannel === 'OFFLINE'
                      ? i18n.get('取消支付')
                      : lastLog.attributes.failureReason}
                  </p>
                  <p>
                    ({i18n.get('批次号')}
                    {lastLog.attributes.channelTradeNo})
                  </p>
                </div>
              }>
              <a
                className="agree mr-16 error color-red"
                onClick={e => {
                  e.stopPropagation()
                  bus.emit('table:row:action', 6 /*'pay'*/, line)
                }}>
                <Icon className="mr-5 color-red" type="exclamation-circle-o" />
                {i18n.get('支付')}
              </a>
            </Tooltip>
          ) : (
            <a
              className="agree mr-16 error"
              onClick={e => {
                e.stopPropagation()
                bus.emit('table:row:action', 6 /*'pay'*/, line)
              }}>
              {i18n.get('支付')}
            </a>
          )}
          <a
            className="reject"
            onClick={e => {
              e.stopPropagation()
              bus.emit('table:row:action', 1 /*'reject'*/, line)
            }}>
            {i18n.get('驳回')}
          </a>
          <a
            className="ant-dropdown-link ml-16"
            onClick={e => bus.emit('table:row:action', 16 /*'receive_print'*/, line)}>
            {i18n.get('收到打印')}
          </a>
        </div>
      )
    },
  }
}


export function createActionDetailsColumn4Pay(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    className: 'actions-wrapper',
    sorter: false,
    allowReordering: false,
    allowHiding: true,
    allowGrouping: false,
    minWidth: 160,
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            return false
          }}>
          <a
            className="agree mr-16 error"
            onClick={e => bus.emit('table:row:action', 6 /*'pay'*/, line)}>
            {i18n.get('支付')}
          </a>
          <a
            className="reject"
            onClick={e => bus.emit('table:row:action', 1 /*'reject'*/, line)}>
            {i18n.get('驳回')}
          </a>
        </div>
      )
    },
  }
}

export function createActionColumn4PartialPay(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      if (!line.flowId) return <div />
      let logs = (line.flowId && line.flowId.logs) || []
      let lastLog = logs[logs.length - 1] || {}
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          {lastLog.state === 'failure' ? (
            <Tooltip
              overlay={
                <div>
                  <p>
                    {i18n.get('失败原因')}
                    {i18n.get('：')}
                    {lastLog.attributes.paymentChannel === 'OFFLINE'
                      ? i18n.get('取消支付')
                      : lastLog.attributes.failureReason}
                  </p>
                  <p>
                    ({i18n.get('批次号')}
                    {lastLog.attributes.channelTradeNo})
                  </p>
                </div>
              }>
              <a
                className="agree mr-16 error color-red"
                onClick={e => {
                  e.stopPropagation()
                  bus.emit('table:row:action', 6 /*'pay'*/, line)
                }}>
                <Icon className="mr-5 color-red" type="exclamation-circle-o" />
                {i18n.get('支付')}
              </a>
            </Tooltip>
          ) : (
            <a
              className="agree mr-16 error"
              onClick={e => {
                e.stopPropagation()
                bus.emit('table:row:action', 6 /*'pay'*/, line)
              }}>
              {i18n.get('支付')}
            </a>
          )}
          <a
            className="ant-dropdown-link ml-16"
            onClick={e => bus.emit('table:row:action', 16 /*'receive_print'*/, line)}>
            {i18n.get('收到打印')}
          </a>
        </div>
      )
    },
  }
}

export function createActionsColumn4Send(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          {line.state === 'UNREAD' && (
            <a
              className="ant-dropdown-link mr-16"
              onClick={e => bus.emit('table:row:action', 10 /*'reject'*/, line)}>
              {i18n.get('标记已读')}
            </a>
          )}
          {
            isPrintShow({selectedRowsData: line}) && (
            <a
              className="ant-dropdown-link mr-16"
              onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
              {i18n.get('打印')}
            </a>
            )
          }
        </div>
      )
    },
  }
}

export function createActionColumn4WaitToSend(bus) {
  return {
    title: getActionLabel(),
    fixed: '',
    width: 320,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    className: 'actions-wrapper fs-14',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 14, line)}>
            {i18n.get('添加寄送信息')}
          </a>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 15, line)}>
            {i18n.get('跳过寄送')}
          </a>
          {
            isPrintShow({selectedRowsData: line}) && (
              <a
                className="ant-dropdown-link mr-16"
                onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
                {i18n.get('打印')}
              </a>
            )
          }
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 16 /*'receive_print'*/, line)}>
            {i18n.get('收到打印')}
          </a>
        </div>
      )
    },
  }
}

export function createActionsColumn4Sent(bus) {
  return {
    title: i18n.get('操作'),
    fixed: '',
    width: 280,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: i18n.get('操作'),
    value: 'action',
    className: 'actions-wrapper fs-14',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          {
            isPrintShow({selectedRowsData: line}) && (
              <a
                className="ant-dropdown-link mr-16"
                onClick={e => bus.emit('table:row:action', 8 /*'print'*/, line)}>
                {i18n.get('打印')}
              </a>
            )
          }
          {/* <a className="ant-dropdown-link mr-16" onClick={e => bus.emit('table:row:action', 12 , line)}>
            {i18n.get('评论')}
            </a> */}
        </div>
      )
    },
  }
}

export function createActionColumn4Confirm(bus) {
  return {
    title: getActionLabel(),
    width: 200,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    //fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="ant-dropdown-link mr-16"
            onClick={e => bus.emit('table:row:action', 16, line, 'confirm')}>
            {i18n.get('确认发票')}
          </a>
        </div>
      )
    },
  }
}

export function createActionColumn4Paid(source, from = '') {
  return {
    title: getActionLabel(),
    width: 120,
    filterType: false,
    dataIndex: 'action',
    key: 'action',
    label: getActionLabel(),
    value: 'action',
    // fixed: 'right',
    className: 'actions-wrapper',
    render(text, line) {
      const _line = from === 'auditPayment' ? {
        flowId: line?.sourceId,
        channelTradeNo: line?.dataLink?.E_system_paymentRecord_支付批次号,
        channel: line?.dataLink?.E_system_paymentRecord_支付方式
      } : line
      return (
        <div
          className="actions"
          onClick={e => {
            e.persist()
            e.nativeEvent.stopImmediatePropagation()
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          <a
            className="reject"
            onClick={e => {
              e.stopPropagation()
              app.open('@audit:PayLogModal', { line: _line, source })
            }}>
            {i18n.get('支付日志')}
          </a>
        </div>
      )
    },
  }
}

export default createRiskWarningColumn
