import { app as api } from '@ekuaibao/whispered';
import { getRiskCountInBatchAgree, getRiskCountInBatchByConditions } from '../audit-action';

export async function getRiskCount(param) {
  const { flowIds, fetchParams, isSelectAll, types } = param;
  if (isSelectAll) {
    return getRiskCountInBatchByConditions({ fetchParams, types });
  }
  return getRiskCountInBatchAgree({ flowIds, types });
}

export async function getApprovalState() {
  const approveAction = () => {
    return Promise.resolve();
  };
  const result = await api.open('@audit:AgreeBillModal', {
    showLoading: true,
    approveAction,
    isBatchAgree: true,
  });

  const { handleBatchResult } = await import('../service');
  handleBatchResult(result, 'batchAgree');
}
