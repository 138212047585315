import { __assign } from "tslib";
/**
 *  Created by panwei on 2018/12/26 7:07 PM.
 */
import { get } from 'lodash';
// @ts-ignore
import { MoneyMath } from '@ekuaibao/money-math';
var riskWarnTextMap = function () { return ({
    budget: i18n.get('预算超额'),
    costControl: i18n.get('费用标准超标'),
    invoiceReview: i18n.get('未收到全部发票'),
    submit: i18n.get('非首次提交'),
}); };
var noRiskTextMap = function () { return ({
    budget: i18n.get('无预算超额'),
    costControl: i18n.get('无费用标准超标'),
    invoiceReview: i18n.get('发票均已收到'),
    submit: i18n.get('首次提交'),
}); };
var riskWranLoading = {
    budget: i18n.get('正在检查预算是否超标…'),
    costControl: i18n.get('正在检查费用标准是否超标…'),
    invoiceReview: i18n.get('正在检查纸质发票是否已全部收到…'),
    submit: i18n.get('正在检查单据是否首次提交…'),
};
export var RiskStatus;
(function (RiskStatus) {
    RiskStatus[RiskStatus["Error"] = 0] = "Error";
    RiskStatus[RiskStatus["Warning"] = 1] = "Warning";
    RiskStatus[RiskStatus["Success"] = 2] = "Success";
    RiskStatus[RiskStatus["Processing"] = 3] = "Processing";
})(RiskStatus || (RiskStatus = {}));
export function checkRisk(type, rep) {
    var _a, _b, _c, _d;
    if (rep === void 0) { rep = {}; }
    var error = rep.error, _e = rep.value, value = _e === void 0 ? {} : _e;
    var flowCount = value.flowCount;
    var text = flowCount === 0 ? noRiskTextMap()[type] : riskWarnTextMap()[type];
    if (error) {
        return _a = {},
            _a[type] = { text: i18n.get("{__k0}\u52A0\u8F7D\u5931\u8D25", { __k0: text }), status: RiskStatus.Error },
            _a;
    }
    if (flowCount) {
        return _b = {},
            _b[type] = {
                text: i18n.get("\u6709 {__k0} \u5F20\u5355\u636E{__k1}", { __k0: flowCount, __k1: text }),
                status: RiskStatus.Warning,
            },
            _b;
    }
    if (flowCount === 0) {
        return _c = {}, _c[type] = { text: text, status: RiskStatus.Success }, _c;
    }
    text = riskWranLoading[type];
    return _d = {}, _d[type] = { text: text, status: RiskStatus.Processing }, _d;
}
export function moneyGroupData(datas, inPay) {
    if (inPay === void 0) { inPay = false; }
    var map = {}, arr = [];
    datas
        .filter(function (line) {
        return !!getBillMoney(line, inPay);
    })
        .forEach(function (line) {
        var money = getBillMoney(line, inPay);
        // @ts-ignore
        var _a = money || {}, standardStrCode = _a.standardStrCode, standardNumCode = _a.standardNumCode;
        var value = map[standardStrCode];
        if (value) {
            map[standardStrCode] = __assign(__assign({}, new MoneyMath(value).add(money).value), { standardNumCode: standardNumCode });
        }
        else {
            map[standardStrCode] = __assign(__assign({}, new MoneyMath(money).value), { standardNumCode: standardNumCode });
        }
    });
    for (var key in map) {
        arr.push(map[key]);
    }
    return arr;
}
function getBillMoney(line, inPay) {
    if (inPay === void 0) { inPay = false; }
    if (inPay) {
        return get(line, "flowId.form.payMoney") || get(line, "form.payMoney");
    }
    var type = get(line, 'flowId.formType') || get(line, 'formType');
    if (type === 'payment') {
        return get(line, "flowId.form.payMoney") || get(line, "form.payMoney");
    }
    return get(line, "flowId.form." + type + "Money") || get(line, "form." + type + "Money");
}
