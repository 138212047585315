/**
 *  Created by pw on 2021/3/11 下午12:57.
 */
import { __assign, __read } from "tslib";
import { app } from '@ekuaibao/whispered';
export function trackAuditFistTime(backlogParam) {
    if (!!window.__AUDIT_FIRST_TIME) {
        var status = backlogParam === null || backlogParam === void 0 ? void 0 : backlogParam.status;
        if (status && status.length) {
            var _a = __read(status, 1), firstStatus = _a[0];
            track('待办列表首次加载', 'myFlowTableFirstTime', firstStatus);
        }
        else {
            track('待办列表首次加载', 'myFlowTableFirstTime');
        }
    }
    window.__AUDIT_FIRST_TIME = 0;
}
export function trackAuditNextTime(backlogParam) {
    var _a;
    var currentPage = (_a = backlogParam === null || backlogParam === void 0 ? void 0 : backlogParam.page) === null || _a === void 0 ? void 0 : _a.currentPage;
    if (currentPage > 1) {
        var status = backlogParam === null || backlogParam === void 0 ? void 0 : backlogParam.status;
        if (status && status.length) {
            var _b = __read(status, 1), firstStatus = _b[0];
            track('待办列表点击下一页', 'myFlowTableNextTime', firstStatus);
        }
        else {
            track('待办列表点击下一页', 'myFlowTableNextTime');
        }
    }
}
export function newTrack(key, options) {
    var _a, _b, _c, _d, _e;
    window.TRACK &&
        window.TRACK(key, __assign({ source: window.__PLANTFORM__, userId: (_b = (_a = app.getState()['@common'].userinfo) === null || _a === void 0 ? void 0 : _a.staff) === null || _b === void 0 ? void 0 : _b.userId, corName: (_e = (_d = (_c = app.getState()['@common'].userinfo) === null || _c === void 0 ? void 0 : _c.staff) === null || _d === void 0 ? void 0 : _d.corporation) === null || _e === void 0 ? void 0 : _e.name }, options));
}
function track(actionName, eventName, status) {
    var _a, _b, _c, _d, _e;
    if (window.TRACK) {
        var userInfo = (_a = app.getState()['@common'].userinfo) === null || _a === void 0 ? void 0 : _a.data;
        var corpId = ((_c = (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.staff) === null || _b === void 0 ? void 0 : _b.corporationId) === null || _c === void 0 ? void 0 : _c.id) ? (_d = userInfo === null || userInfo === void 0 ? void 0 : userInfo.staff) === null || _d === void 0 ? void 0 : _d.corporationId.id : (_e = userInfo === null || userInfo === void 0 ? void 0 : userInfo.staff) === null || _e === void 0 ? void 0 : _e.corporationId;
        var params = {
            actionName: actionName,
            corpId: corpId,
            decice: 'Web',
            status: status,
        };
        if (eventName === 'myFlowTableFirstTime') {
            params[eventName] = Date.now() - window.__AUDIT_FIRST_TIME;
        }
        window.TRACK('myAuditTableTime', params);
    }
}
