import React from 'react'
import { Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showMessage } from '@ekuaibao/show-util'

function handleCopyBtnClick() {
  showMessage.success(i18n.get('复制成功！'))
}

function renderUrlCopy(url) {
  const textData = url.replace(/\s/g, '')
  return (
    <CopyToClipboard text={textData}>
      <div type="primary" onClick={handleCopyBtnClick} style={{ cursor: 'pointer' }}>
        {i18n.get('复制')}
      </div>
    </CopyToClipboard>
  )
}

export function renderOpenLinkDesc(title, desc, url, tip) {
  const tipText = tip ? tip : i18n.get('刷新完成前请不要关闭此窗口')
  return (
    <>
      <div
        style={{
          marginTop: -5,
          color: 'black',
          fontSize: 20,
          fontWeight: 500,
          marginBottom: 12
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: 14,
          color: 'rgba(0, 0, 0, 0.65)'
        }}
      >
        {desc}
      </div>
      {url && (
        <div
          style={{
            padding: 8,
            marginTop: 10,
            backgroundColor: '#f5f5f5',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          <Tooltip placement="top" title={renderUrlCopy(url)}>
            {url}
          </Tooltip>
        </div>
      )}
      {url && <div style={{ marginTop: 10, color: 'rgba(0, 0, 0, 0.65)' }}>{tipText}</div>}
    </>
  )
}

export default renderOpenLinkDesc
