import './audit.less'
import React from 'react'
import { app as api, app } from '@ekuaibao/whispered'
import { isArray, isObject } from '@ekuaibao/helpers'
import parseQuery2Select from '@ekuaibao/lib/lib/parseQuery2Select'
import {
  getNodeValueByPath,
} from '@ekuaibao/lib/lib/lib-util'
import { showModal, showMessage } from '@ekuaibao/show-util'
import { Fetch } from '@ekuaibao/fetch'
import * as actions from './audit-action'
import {
  changePaymentToOffline,
  refreshSOUCHEPayment,
  receivePrint,
  refreshDoubleSOUCHEPayment,
} from './util/fetchUtil'
import { fnGetPayAmount } from './util/Utils'
import {
  postPayPlanRepay,
  payAgin,
  setPayState,
  getFailedFlow,
  checkFlowIsAllRequired,
  postPaymentManagementApproval,
} from './audit-action'
import { get, cloneDeep, uniqBy } from 'lodash'
import { newTrack } from './util/trackAudit'
import qs from 'qs'
import { renderOpenLinkDesc } from './service-renderOpenLinkDesc'
import { doPrint, handlePrint } from './service-print'
import { Questionnaire } from '@hose/eui'
import { billTypeMap } from '@ekuaibao/lib/lib/enums'
import { DatePicker } from 'antd'
export { renderOpenLinkDesc, doPrint, handlePrint }
import moment from 'moment'
import localeCN from 'antd/es/date-picker/locale/zh_CN'
import localeEN from 'antd/es/date-picker/locale/en_US'

const urlParams = qs.parse(location.search.slice(1))
const isWebchat = window.isWebchat

const continueMsg = _ => i18n.get('继续')
const cancelMsg = _ => i18n.get('取消')
const stateUpdate = _ => i18n.get('状态已更新')
const sureMsg = _ => i18n.get('确定')
const closeMsg = _ => i18n.get('关闭')
const fail = _ => i18n.get('操作失败')
const complete = _ => i18n.get('已支付完成')
const refreshed = _ => i18n.get('已刷新完成')

export async function batchRefresh(callback) {
  return CHANPAYAction({ channel: 'CHANPAYV2' }, callback, true)
}

function cancelPayment(line, type = 'paymentBatch') {
  const id = line.id

  let fn = () => {
    if (type === 'paymentBatch') {
      api.dispatch(actions.cancelPayBatch({ id })).then(_ => {
        api.emit('reload:pending:paying')
      })
    } else {
      api.dispatch(actions.cancelPaymentByBatch({ flowId: id, batchId: line.batchId })).then(_ => {
        api.emit('reload:pending:paying')
      })
    }
  }
  let isErp = type === 'paymentBatch' ? line.channel === 'ERP' : line.paymentChannel
  if (isErp) {
    const content =
      type !== 'paymentBatch' && line.paymentChannel !== 'OFFLINE'
        ? i18n.get('可能会造成第三方支付重复支付，是否继续操作？')
        : i18n.get('是否继续操作？')
    showModal.confirm({
      content,
      okText: continueMsg(),
      cancelText: cancelMsg(),
      onOk() {
        fn()
      },
    })
  } else {
    fn()
  }
}

function confirmPayment(line, type) {
  const id = line.id
  let offLineFinishedTime = moment()
  let fn = finishTime => {
    if (type === 'paymentBatch') {
      const params = { id }
      finishTime && (params.finishTime = finishTime)
      api.dispatch(actions.confirmPayBath(params)).then(_ => {
        api.emit('reload:pending:paying')
      })
    } else {
      api.dispatch(actions.confirmPayment({ id })).then(_ => {
        api.emit('reload:pending:paying')
      })
    }
  }

  const offlineFinishTimeAvailable = api.getState()['@audit'].offlineFinishTimeAvailable

  if (line.channel === 'ERP') {
    return showModal.confirm({
      content: i18n.get('可能会造成第三方支付状态不一致，是否继续操作？'),
      okText: continueMsg(),
      cancelText: cancelMsg(),
      onOk() {
        fn()
      },
    })
  }
  if (line.channel === 'CHANPAYV2') {
    const { id, flowIds, balance, channel, ekbTradeNo } = line
    const count = flowIds?.length
    return CHANPAYAction(
      {
        batchId: id,
        count,
        money: balance,
        channel,
        ekbTradeNo,
      },
      () => {
        api.emit('reload:pending:paying')
      },
    )
  }
  if (line.channel === 'OFFLINE' && offlineFinishTimeAvailable) {
    return showModal.confirm({
      content: (
        <>
          {i18n.get('实际支付完成时间：')}
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            allowClear={false}
            style={{ width: '200px' }}
            defaultValue={offLineFinishedTime}
            onChange={date => {
              offLineFinishedTime = date
            }}
            locale={Fetch.defaultLanguage === 'zh-CN' ? localeCN : localeEN}
          />
        </>
      ),
      okText: sureMsg(),
      iconType: 'nothing',
      cancelText: cancelMsg(),
      onOk() {
        fn(offLineFinishedTime?.valueOf())
      },
    })
  }
  fn()
}

function changePaymentOffline(line) {
  const { id } = line
  const action = () => {
    changePaymentToOffline({ id })
      .then(_ => {
        showMessage.success(i18n.get('线下支付成功'))
        api.emit('reload:pending:paying')
      })
      .catch(err => {
        showMessage.error(i18n.get(err?.errorMessage || err?.message))
      })
  }
  let str = i18n.get('切换为线下支付后，您可通过评论的方式上传支付凭证。')
  if (line?.state === 'PENDING') {
    str = i18n.get('可能会造成第三方支付重复...')
  }
  showModal.confirm({
    content: i18n.get(str),
    okText: sureMsg(),
    cancelText: cancelMsg(),
    onOk() {
      action()
    },
  })
}

function _checkPayStatus(line, otherParamsData, cb) {
  const otherParams = otherParamsData ?? {}
  api
    .dispatch(actions.updatePaymentState({ id: line.id, ...otherParams }))
    .then(response => {
      let value = response && response.value
      cb && cb()
      switch (value.state) {
        case 'FAILURE':
          showModal.error({
            title: i18n.get('无法支付'),
            content: value.msg,
            onOk: () => {
              api.invokeService('@audit:getPaymentBatch')
            },
          })
          break
        case 'SUCCESS':
          showModal.info({
            title: stateUpdate(),
            content: i18n.get('tradeNo-success', {
              tradeNo: line.channelTradeNo,
            }),
            onOk: () => {
              api.invokeService('@audit:getPaymentBatch')
            },
          })
          break
        case 'CHECKING':
          showModal.info({
            title: stateUpdate(),
            content: (
              <div>
                <p>{i18n.get('tradeNo-pending', { tradeNo: line.channelTradeNo })}</p>
                <p>
                  {i18n.get('请前往')}
                  <a
                    onClick={() =>
                      value.checkAddr && api.emit('@vendor:open:link', value.checkAddr.url)
                    }>
                    {i18n.get('[现金罗盘]')}
                  </a>
                  {i18n.get('进行审核')}
                </p>
              </div>
            ),
            onOk: () => {
              api.invokeService('@audit:getPaymentBatch')
            },
          })
          break
        case 'PROCESSED':
          let msg = ''
          if (value.msg && value.msg.length) {
            let msgArr = value.msg.split(i18n.get('。'))
            msg = msgArr[0]
          }

          showModal.info({
            title: stateUpdate(),
            content: i18n.get('tradeNo-custom', {
              tradeNo: line.channelTradeNo,
              msg: msg,
            }),
            onOk: () => {
              api.invokeService('@audit:getPaymentBatch')
            },
          })
          break
        case 'PENDING':
          value.msg && showMessage.info(value.msg)
          let url = line.channel !== 'WALLET' && line.url
          url && api.emit('@vendor:open:link', url)
          break
      }
    })
    .catch(error => {})
}

function checkPayStatus(line) {
  api.emit('audit-payment:show:captcha:modal', {
    _checkPayStatus,
    line,
  })
}

function getSelectedRowDataIds(selectedRowData) {
  return Object.keys(selectedRowData).map(key => selectedRowData[key].id)
}

function getSelectedRowFlowIds(selectedRowData) {
  return Object.keys(selectedRowData).map(key => {
    const v = selectedRowData[key]
    return v.flowId ? v.flowId.id : v.id
  })
}

async function _fnGetPayAmount(data) {
  const { isByDetail, backlog, flowIds, tableSource } = data
  let res = {}
  if (isByDetail) {
    res = await actions.postPayMoneyByDetail(backlog.map(v => v.id))
  } else if (tableSource === 'partPay') {
    res = await actions.postPartialMoneyByFlow(flowIds)
  } else {
    res = await actions.postPayMoneyByFlow(flowIds)
  }
  return res
}

async function handlePayFlow(_this, backlog, callback, fetchParams, isByDetail, tableSource) {
  const _backlog = Array.isArray(backlog) ? backlog : [backlog]
  let amount
  let flowIds = _backlog.map(v => v.flowId.id)
  let billType = _backlog[0].type
  let res = {}
  res = await _fnGetPayAmount({ isByDetail, backlog: _backlog, flowIds, tableSource })
  const { standardAmount } = fnGetPayAmount(res)
  amount = standardAmount?.standard
  if (amount === null) {
    showMessage.warning(i18n.get('所选单据本位币不一致，不能一起发起支付，请重新勾选!'))
    return
  }
  if (amount * 1 === 0) {
    //金额为0的支付
    _handleZeroPayment(_this, _backlog, isByDetail, standardAmount, callback)
  } else {
    const { items } = (await getFailedFlow(flowIds)) || { items: [] }
    let tipsModalRes
    if (items?.length) {
      tipsModalRes = await api.open('@audit:PayTipsModal', {
        failedFlows: items,
        flowIds,
      })
      if (!tipsModalRes || (tipsModalRes?.checked && items.length === _backlog.length)) {
        return
      }
    }
    const failedIds = items.map(v => v.id)
    const flowIdList = !tipsModalRes?.checked
      ? flowIds
      : flowIds.filter(v => !failedIds.includes(v))
    let backlogList = !tipsModalRes?.checked
      ? _backlog
      : _backlog.filter(v => !failedIds.includes(v.flowId?.id))
    let dataSource = {}
    dataSource = await _fnGetPayAmount({
      isByDetail,
      backlog: _backlog,
      flowIds: flowIdList,
      tableSource,
    })
    const { amountList, standardAmount } = fnGetPayAmount(dataSource)
    if (standardAmount?.standard === null) {
      showMessage.warning(i18n.get('所选单据本位币不一致，不能一起发起支付，请重新勾选!'))
      return
    }
    api
      .open('@audit:PayMethodModal', {
        data: {
          backlog: backlogList,
          _this,
          callback,
          billType,
          fetchParams,
          flowIds: flowIdList,
          isByDetail,
          amountList,
        },
      })
      .then(data => {
        // 是否过滤手动确认失败过的单据
        //isCaptchaClose 短信验证，调用_payFlow方法再关闭弹框，所以此时不需要重复调用
        if (!data.isCaptchaClose) {
          data.flowIds = data.isUsePaymentPlan ? [] : flowIdList
          if (data.isUsePaymentPlan && !isByDetail) {
            const _flowIds = data.paymentPlans.map(i => i.sourceId)
            backlogList = backlogList.filter(i => _flowIds.includes(i.flowId.id))
          }
          _payFlow(_this, data, callback, billType, backlogList, data.successCb)
        }
      })
  }
}

function batchShowModalTitle(failIndex, sucIndex, type) {
  let title = i18n.get('批量驳回成功')
  if (type === 'agree') {
    title = i18n.get('同意成功')
  } else if (type === 'batchAgree') {
    title = i18n.get('批量同意完成')
  } else if (type === 'reject') {
    title = i18n.get('驳回成功')
  } else if (type === 'add-node') {
    title = i18n.get('批量操作成功')
  } else if (type === 'printremind') {
    title = i18n.get('批量提醒成功')
  } else if (type === 'receive') {
    title = i18n.get('批量收单完成')
  } else if (type === 'sendExpress') {
    title = i18n.get('批量寄送完成')
  } else if (type === 'singleAgree') {
    title = i18n.get('审批同意完成')
  } else if (type === 'receiveException') {
    title = i18n.get('异常收单完成')
  } else if (type === 'cancelReceiveException') {
    title = i18n.get('取消异常收单完成')
  }
  // 太怪异了，4年的代码了没人动，本地没问题，打包后样式就不生效了，暂时加上行内样式
  return (
    <div className="batch-show-title" style={{ textAlign: 'center' }}>
      <div
        className="title"
        style={{
          height: '24px',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          color: '#1d2b3d',
        }}>
        {i18n.get(title)}
      </div>
      <div
        className="title-content"
        style={{
          marginTop: '4px',
          height: '22px',
          fontSize: '14px',
          lineHeight: '22px',
          color: 'rgba(29, 43, 61, 0.75)',
        }}>{`${sucIndex}${i18n.get('条审批成功，')}${failIndex}${i18n.get('条审批失败')}`}</div>
    </div>
  )
}

function batchShowModalContent(items) {
  return (
    <div className="batch-show-agree-modal">
      {items &&
        items.map((item, index) => {
          return (
            <div className="batch-item color-gray" key={index}>
              <span className="mark" />
              &nbsp;&nbsp;
              <span>{item.code || item.flowCode}</span>
              &nbsp;&nbsp;
              <span>{item.title || item.flowTitle}</span>
              &nbsp;&nbsp;
              <span>{item.message || item.msg}</span>
            </div>
          )
        })}
    </div>
  )
}

async function _handlePayPlanCHANPAY(batch, callback, channel) {
  const { batchId, seqNos = [], balance, ekbTradeNo, needReview } = batch
  setTimeout(async () => {
    await CHANPAYAction(
      { batchId, seqNos, count: 1, channel, money: balance, ekbTradeNo, needReview },
      callback,
    )
  }, 1000)
}

async function _handleCHANPAY(batch, backlogData, callback, channel) {
  const backlog = backlogData ?? []
  const { getMoney } = app.require('@lib/misc')

  const moneys = (isArray(backlog) ? backlog : [backlog])
    .filter(item => getMoney(item.flowId.form.payMoney) * 1 > 0)
    .map(item => getMoney(item.flowId.form.payMoney))
  const count = moneys.length
  const { batchId, seqNos = [], balance, ekbTradeNo, needReview, currency } = batch
  const totalMoney = balance ? balance : moneys.reduce((a, b) => new Big(a).plus(b).toFixed(2), 0)
  const money = { standard: totalMoney, standardStrCode: currency?.strCode }
  await setTimeout(async () => {
    await CHANPAYAction(
      { batchId, seqNos, count, channel, money, ekbTradeNo, needReview },
      callback,
    )
  }, 1000)
}

async function CHANPAYAction(
  {
    batchId,
    seqNos = [],
    count,
    money,
    channel = 'CHANPAY',
    ekbTradeNo,
    ekbTradeNos = [],
    needReview = false,
  },
  fn,
  isRefresh = false,
) {
  const flag = needReview && !isRefresh
  if (flag) {
    fn?.()
    return
  }
  let params = `platform=${window.__PLANTFORM__}&ekbCorpId=${Fetch.ekbCorpId}&corpId=${
    Fetch.corpId
  }&token=${Fetch.accessToken}&pluginType=${channel === 'CHANPAY' ? 'payment' : 'paymentV2'}${
    ekbTradeNo ? `&ekbTradeNo=${ekbTradeNo}` : ''
  }`
  let title =
    window.__PLANTFORM__ === 'WEIXIN'
      ? i18n.get('请复制链接到浏览器上完成支付')
      : i18n.get('请在新打开的页面上完成支付')
  let desc =
    window.__PLANTFORM__ === 'WEIXIN'
      ? i18n.get('由于企业微信限制，请复制此链接到浏览器上进行相关操作')
      : i18n.get('支付完成前请不要关闭此窗口')
  let oktext = complete()
  if (isRefresh) {
    params += `&isRefresh=true&ekbTradeNos=${ekbTradeNos.join()}`
    title =
      window.__PLANTFORM__ === 'WEIXIN'
        ? i18n.get('请复制链接到浏览器上完成刷新')
        : i18n.get('请在新打开的页面上完成刷新')
    desc =
      window.__PLANTFORM__ === 'WEIXIN'
        ? i18n.get('由于企业微信限制，请复制此链接到浏览器上进行相关操作')
        : i18n.get('刷新完成前请不要关闭此窗口')
    oktext = refreshed()
  } else {
    const messageCode = await api.invoke('@vendor:dd:message:code')
    params += `&message=${messageCode}&batchId=${batchId}&seqNos=${seqNos}&count=${count}&money=${
      money?.standard ?? money
    }&strCode=${money?.standardStrCode ?? 'CNY'}&`
  }
  params = encodeURIComponent(params)
  let url = `/web/payment.html?${params}`
  url = `${location.origin}${url}`
  /**
   * bbsazdLsGM0g00 是私有化客户任买的企业id
   * DTj4_Yucjk_Eyw 是460mix环境中可测试银企联支付的合思信息的企业id
   * PRP-15316 工单03#4339
   * 简要描述遇到的问题：银企直连无法直接跳转到支付界面进行付款，需要复制链接才可以
   * 问题影响：客户无法接受这种方式
   * 个人认为现有的方案是合理的，因企业微信中不能识别U盾，不能在应用内弹出的银企联弹窗内完成支付，所以提供了复制弹窗
   * 但因客户强烈要求，现根据corpid判断，不再为此用户弹出用于复制链接的窗口，直接跳转至支付页
   * 2020年3月23日晚上线，次日上午，coe反馈：客户认同此方案
   * 此用户场景：企业微信用户，在进入应用后，点击右上角的浏览器按钮，跳转至默认浏览器使用
   */
  if (
    window.__PLANTFORM__ === 'WEIXIN' &&
    window.isWebchat &&
    Fetch.ekbCorpId !== 'bbsazdLsGM0g00'
  ) {
    showModal.info({
      content: renderOpenLinkDesc(title, desc, url),
      okText: complete(),
      onOk() {
        showMessage.info(i18n.get('已经发起查询，请稍后通过刷新支付中页面获取查询结果'), 2)
        fn && fn()
      },
    })
  } else {
    api.emit('@vendor:open:link', url)
    showModal.info({
      content: renderOpenLinkDesc(title, desc),
      okText: oktext,
      onOk() {
        showMessage.info(i18n.get('已经发起查询，请稍后通过刷新支付中页面获取查询结果'), 2)
        fn && fn()
      },
    })
  }
}

async function SOUCHERefresh(dataList, callback, channel) {
  if (Array.isArray(channel)) {
    const { msg = '', service_resp_desc = '' } = await refreshDoubleSOUCHEPayment(dataList, channel)
    showMessage.info(msg || service_resp_desc)
    showMessage.info(i18n.get('已经发起查询，请稍后通过刷新支付中页面获取查询结果'), 2)
    callback && callback()
  } else {
    const batchIds = dataList.map(item => item.id)
    const { msg, service_resp_desc } = await refreshSOUCHEPayment(batchIds, channel)
    showMessage.info(msg || service_resp_desc)
    showMessage.info(i18n.get('已经发起查询，请稍后通过刷新支付中页面获取查询结果'), 2)
    callback && callback()
  }
}

function payMethodDesc(channel, dynamicChannelMap) {
  channel = channel === 'CHANPAYV2' ? 'CHANPAY' : channel
  const channelObj = dynamicChannelMap[channel]
  return i18n.get('{payment}paying', { payment: i18n.get(channelObj.name) })
}

function renderPayDesc(_this, channelTradeNo, flowIds, billType, payMethodDesc, desc) {
  const { exportSelectedBills } = app.require('@lib/export-excel-service')
  let ids = []
  flowIds.forEach(el => {
    if (typeof el === 'string') {
      ids.push(el)
    } else {
      ids.push(el.id)
    }
  })
  let style = {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 12,
  }
  return (
    <div>
      <div style={style}>{i18n.get('批次号') + `${channelTradeNo}  ${payMethodDesc}`}</div>
      <div style={style}>{desc}</div>
      {flowIds && flowIds.length && (
        <a
          style={{ color: 'var(--brand-base)', fontSize: 12 }}
          onClick={() => exportSelectedBills({ data: ids })}>
          {i18n.get('导出Excel')}
        </a>
      )}
    </div>
  )
}

function _handleZeroPayment(_this, backlog, isByDetail, standardAmount, callback) {
  const flowIds = backlog.map(v => v.flowId.id)
  api.open('@audit:ZeroPayModal', { flowIds, standardAmount }).then(res => {
    const paymentPlans = backlog.map(v => {
      return {
        ...v,
        flowId: v.flowId?.id ?? v.flowId,
        payeeId: v.payeeId?.id ?? v.payeeId,
        instance: null,
      }
    })
    const currency = {
      symbol: standardAmount?.symbol || '¥',
      strCode: standardAmount?.strCode || 'CNY',
      numCode: standardAmount?.numCode || '156',
    }
    const params = {
      ...res,
      flowIds: isByDetail ? [] : flowIds,
      paymentPlans: isByDetail ? paymentPlans : [],
      channel: 'ZERO',
      isUsePaymentPlan: isByDetail,
      accountId: '', // 后端参数校验，必须要有这个字段
      currency,
    }
    api.dispatch(actions.payBackLog(params)).then(response => {
      callback && callback()
      api.invokeService(`@common:get:backlog:count:payment`)
    })
  })
}

export function _payFlow(_this, data, callback, billType, backlog, successCb, errorCb) {
  const { getMoney } = app.require('@lib/misc')

  const { channel, isPayPlanRepay, payMoney, paymentPlans = [], hsbcCallBack } = data
  let dynamicChannelMap = {}
  if (data.dynamicChannelMap) {
    dynamicChannelMap = data.dynamicChannelMap
    delete data.dynamicChannelMap
  }

  const amount = isPayPlanRepay ? payMoney : get(backlog, 'flowId.form.payMoney')
  const sendMessage =
    backlog.length > 0
      ? backlog.filter(item => getMoney(item.flowId.form.payMoney) * 1 > 0).length
      : getMoney(amount) * 1 > 0
  backlog.length > 10 && api.dispatch(actions.setInBatchPaying(true))
  const _paymentPlans = paymentPlans.map(v => ({
    ...v,
    flowId: v.flowId?.id ?? v.flowId,
    payeeId: v.payeeId?.id ?? v.payeeId,
    instance: null,
  }))
  const _data = { ...data, paymentPlans: _paymentPlans }
  api
    .dispatch(actions.payBackLog(_data, sendMessage))
    .then(response => {
      let batch = response.value
      if (batch?.needReview) {
        showMessage.info(i18n.get('已进入复核中'))
      }
      if (!batch?.needReview && hsbcCallBack) {
        callback && callback()
        backlog.length > 10 && api.dispatch(actions.setInBatchPaying(false))
        return hsbcCallBack(batch)
      }
      if (channel === 'CHANPAY' || channel === 'CHANPAYV2') {
        //银企联走原方法... 改不动
        return _handleCHANPAY(batch, backlog, callback, channel)
      } else {
        // 新渠道走新方法...
        return payByEveryoneChannel(
          _this,
          batch,
          callback,
          channel,
          dynamicChannelMap,
          billType,
          backlog,
        )
      }
    })
    .then(_ => {
      api.invokeService(`@common:get:backlog:count:payment`)
      backlog.length > 10 && api.dispatch(actions.setInBatchPaying(false))
      successCb && successCb()
    })
    .catch(error => {
      backlog.length > 10 && api.dispatch(actions.setInBatchPaying(false))
      errorCb && errorCb(error)
    })
}

function payByEveryoneChannel(
  _this,
  batch,
  callback,
  channel,
  dynamicChannelMap,
  billType,
  backlog,
  flowId,
) {
  const channelObj = dynamicChannelMap[channel]
  const { needReview, url } = batch
  const flag = !needReview && channelObj.needPopup
  if (flag) {
    //渠道是否支持弹窗提示
    const needPopupTitle =
      window.__PLANTFORM__ === 'WEIXIN'
        ? i18n.get('请复制链接到浏览器上完成支付')
        : i18n.get('请在新打开的页面完成支付')
    const needPopupDesc =
      window.__PLANTFORM__ === 'WEIXIN'
        ? i18n.get('由于企业微信限制，请复制此链接到浏览器上进行相关操作')
        : i18n.get('支付完成前请不要关闭此窗口。')
    const needPopupOkText = i18n.get('已支付完成')
    const needPopupTips = i18n.get('刷新完成前请不要关闭此窗口')
    setTimeout(async () => {
      await api.invokeService('@custom-payment:open:link:modal', {
        url,
        title: needPopupTitle,
        desc: needPopupDesc,
        okText: needPopupOkText,
        tip: needPopupTips,
        callback,
      })
    }, 1000)
  } else {
    const flowIds = flowId ? [flowId] : getFlowIdsByBacklog(backlog)
    if (channel === 'OFFLINE') {
      return needReview ? callback?.() : offlineChannelTips(_this, batch, flowIds, callback)
    } else {
      const payMethodDesc = i18n.get('{payment}paying', { payment: channelObj.name })
      let desc = ''
      if (channel === 'ERP') {
        desc = i18n.get('请登录插件或ERP系统发起付款。')
      }
      return needReview ? callback?.() : otherChannelTips(_this, batch, flowIds, callback, billType, payMethodDesc, desc)
    }
  }
}

function offlineChannelTips(_this, batch, flowIds, callback, billType) {
  showModal.confirm({
    content: renderPayDesc(
      _this,
      batch.channelTradeNo,
      flowIds,
      billType,
      i18n.get('线下支付中'),
      i18n.get('如果已经支付成功，请点击【线下支付成功】如果正在支付中，请点击【正在线下支付中】'),
    ),
    okText: i18n.get('线下支付成功'),
    cancelText: i18n.get('正在线下支付中'),
    onOk() {
      showMessage.info(i18n.get('确认已支付成功可在支付记录列表进行查看'))
      api
        .dispatch(
          actions.confirmPayBath({
            id: batch.batchId,
          }),
        )
        .then(() => {
          callback && callback()
        })
        .catch(e => {
          console.log(e)
          callback && callback()
        })
    },
    onCancel() {
      showMessage.info(i18n.get('正在线下支付中可在支付中列表进行查看'))
      callback && callback()
    },
  })
}

function otherChannelTips(_this, batch, flowIds, callback, billType, payMethodDesc, desc) {
  showModal.info({
    content: renderPayDesc(_this, batch.channelTradeNo, flowIds, billType, payMethodDesc, desc),
    okText: closeMsg(),
    onOk() {
      callback && callback()
    },
  })
}

function getFlowIdsByBacklog(backlog) {
  const { getMoney } = app.require('@lib/misc')
  if (backlog.length > 0) {
    return backlog
      .filter(item => getMoney(item?.flowId?.form?.payMoney) * 1 > 0)
      .map(line => line.flowId)
  }
  return getMoney(backlog?.flowId?.form?.payMoney) * 1 > 0 ? [backlog.flowId] : ''
}

function agreeBackLog(data, backlog, fn, actionType) {
  const isSingle = actionType === 'single'
  const id = isSingle ? 'id' : 'ids'
  let param = {
    comment: data.comment,
    attachments: data.attachments,
    isSingle,
  }
  param[id] = isSingle ? backlog.id : [backlog.id]
  if (data.autographImageId) {
    param.autographImageId = data.autographImageId
  }

  api.dispatch(actions.agreeBackLog(param)).then(result => {
    handleBatchResult(result, 'agree')
    fn && fn(result)
    initSurvey(backlog)
  })
}

function initSurvey(backlog) {
  const isWhiteList = api.getState()['@common'].isWhiteList
  if (isWhiteList) {
    const { questionnaireConfig } = api.require('@components/utils/questionnaireConfig')
    const type = billTypeMap()[backlog?.flowId?.form?.specificationId.type || 'expense']
    Questionnaire.initSurvey({
      sid: questionnaireConfig?.approve?.sid,
      channelId: questionnaireConfig?.approve?.channelId,
      width: questionnaireConfig?.approve?.width,
      externalUserId: api.getState()['@common'].userinfo?.data?.staff?.userId,
      externalCompanyId: api.getState()['@common'].userinfo?.data?.staff?.corporationId,
      parameters: {
        name: backlog?.flowId?.form?.specificationId.name,
        type,
      },
    })
  }
}

function _handleSingleResult(res, fn) {
  const { errors } = res
  if (errors.length > 0) {
    const error = errors[0]
    showModal.error({
      title: fail(),
      content: error.message,
      okText: sureMsg(),
      onOk() {
        fn()
      },
    })
  } else {
    fn && fn()
  }
}

//寄送和跳过寄送
function sendExpress(flow, backlog, express, fn) {
  express.backlogIds = backlog.id.toString().split(',')
  express.comment = flow.comment
  express.attachments = flow.attachments
  if (flow.autographImageId) {
    express.autographImageId = flow.autographImageId
  }
  actions.sendExpress(express).then(result => {
    if (
      result.value.errors &&
      result.value.errors.filter(o => o.resultCode !== 'OK').length === 0
    ) {
      showMessage.success(i18n.get('寄送成功'))
    }
    handleBatchResult(result, 'sendExpress')
    fn && fn(result)
  })
}

export function handleCheckBackLog(_this, backlog, express, fn) {
  actions.getBacklogFlowplan(backlog).then(plan => {
    if (judgeBillStatus(plan.value)) {
      showBillStatusChangeModal(fn)
    } else {
      api
        .open('@audit:FlowAllowModal', {
          data: {
            flowPlan: plan.value,
            staffs: _this.props.staffs,
            state: backlog.state,
            flowId: backlog.flowId,
            isAgreeFromDetail: true,
          },
        })
        .then(data => {
          let {
            node: { approverId, approverIds },
          } = data
          if (approverId || approverIds) {
            api.dispatch(
              actions.setFlowPlanNode({ ...data.node }, (state, action) => {
                !action.error && sendExpress(data, backlog, express, fn)
              }),
            )
          } else {
            sendExpress(data, backlog, express, fn)
          }
        })
    }
  })
}

async function agreeBillModal(keys, resp, title, fn, params) {
  let flowPlanConfig
  if (params?.flowIds?.length) {
    const { value } = await actions.getBatchFlowConfig({ flowIds: params?.flowIds })
    flowPlanConfig = value
  }
  api.open('@audit:AgreeBillModal', { title, flowPlanConfig }).then(value => {
    let data = { backlogIds: keys, ...value, ...resp }
    actions.sendExpress(data).then(result => {
      handleBatchResult(result, 'sendExpress')
      fn && fn(result)
    })
  })
}

export function handleSendExpressList(keys, type, fn, params) {
  let title = i18n.get('批量寄送')
  if (type === 'jump_express') {
    title = i18n.get('批量跳过')
    api.open('@audit:JumpExpressInfoModal').then(resp => {
      agreeBillModal(keys, resp, title, fn, params)
    })
  } else {
    api.open('@audit:AddExpressInfoModal').then(resp => {
      agreeBillModal(keys, resp, title, fn, params)
    })
  }
}

export async function handleActionImplementation(
  type,
  backlog,
  fn,
  riskData,
  actionType,
  context,
  fetchParams,
  noCheckPermissions,
  isByDetail,
  tableSource = '', // 我对这个表格的这个传参太恶心了，因为调用太多，我不敢动，这个 tableSource 代表表格来源，希望没有新的参数了，用这个进行判断吧，兄弟们！
) {
  let _this = this || context
  let budgetPower = _this?.props?.budgetPower
  if (!this?.props) {
    _this = context
  }
  let Bill_approval_result
  const { showPrintRemindModal } = app.require('@lib/lib-util')
  const { related } = app.require('@elements/feeDetailViewList/Related')
  const { isEmail } = urlParams || {}
  let rejectData
  if (type === 1) {
    let nodes = backlog?.flowId?.plan?.nodes || []
    let taskId = backlog?.flowId?.plan?.taskId
    rejectData = getRejectData(taskId, nodes) || {}
  }
  //this所指代的对象是当前视图
  switch (type) {
    case 0: //打印提醒
      const flow = backlog.flowId
      const { id } = flow
      showPrintRemindModal(() =>
        api.dispatch(actions.printRemindAction([id])).then(res => {
          _handleSingleResult(res, fn)
        }),
      )
      // Bill_approval_result = '打印提醒'
      break
    case 1: //驳回
      api
        .open('@audit:RejectBillModal', {
          list: [backlog],
          spcSetting: rejectData?.spcSetting,
          spcResubmitMethod: rejectData?.spcResubmitMethod,
          spcRejectTo: rejectData?.id,
        })
        .then(data => {
          let subData = []
          subData.push({
            id: isByDetail ? backlog.flowId?.id : backlog.id,
            ...data,
            name: 'freeflow.reject',
          })
          api
            .dispatch(
              isByDetail
                ? actions.spRejectBackLogByFlow({ data: subData })
                : actions.spRejectBackLog({ data: subData }),
            )
            .then(result => {
              handleBatchResult(result, 'reject')
              fn && fn()
            })
        })
      if (backlog?.state === 'APPROVING') {
        Bill_approval_result = '驳回'
      }
      break
    case 3: //同意
      if (backlog.type === 'loan' || !budgetPower) {
        const isFeeExcited =
          backlog.flowId.flowRulePerformLogs &&
          backlog.flowId.flowRulePerformLogs.results.some(r => r.result === 'false')
        if (isFeeExcited) {
          //费用标准超标
          showBudgetModal(
            { message: i18n.get('该单据超过公司费用标准') },
            isFeeExcited,
            'agree',
            _this,
            backlog,
            fn,
            riskData,
            actionType,
          )
        } else {
          //无超标
          handleAgreeFlow(_this, backlog, fn, riskData, actionType)
        }
      } else {
        handleAgreeFlow(_this, backlog, fn, riskData, actionType)
      }
      Bill_approval_result = '同意'
      break
    case 5: //修改
      related.clearRelatedData()
      let billInfoData = cloneDeep(backlog)
      billInfoData = billInfoData.flowId
      billInfoData.state = 'modify'
      const backLogOwnerId = backlog && backlog.ownerId
      if (billInfoData.writtenOff) {
        let flowId = billInfoData.id
        let { records } = billInfoData.writtenOff
        let recordMap = {}
        records.forEach(record => {
          if (!(record.loanInfoId instanceof Object)) {
            recordMap[record.loanInfoId] = record
          }
        })
        let loanInfoIDs = Object.keys(recordMap)
        if (loanInfoIDs.length) {
          let promises = loanInfoIDs.map(id =>
            api.invokeService('@bills:get:loanpackage:detail:info', {
              id,
              flowId,
            }),
          )
          Promise.all(promises)
            .then(items => {
              items.forEach(item => {
                let { value } = item
                let record = recordMap[value.id]
                record.loanInfoId = { id: value.id, remain: value.remain }
              })
              billInfoData.writtenOff.records = Object.values(recordMap)
              return billInfoData
            })
            .then(value => {
              openModifyBillModal(value, fn, backLogOwnerId, riskData)
            })
        } else {
          openModifyBillModal(billInfoData, fn, backLogOwnerId, riskData)
        }
      } else {
        openModifyBillModal(billInfoData, fn, backLogOwnerId, riskData)
      }
      // Bill_approval_result = '修改'
      break
    case 6: //支付
      const ids = backlog?.length ? backlog.map(v => v.flowId.id) : backlog.flowId.id
      const allFieldCheckin = await checkFlowAllRequiredFields(ids)
      if (!allFieldCheckin) {
        return
      }
      if (backlog.type === 'loan' || !budgetPower) {
        handlePayFlow(_this, backlog, fn, fetchParams, isByDetail, tableSource)
      } else {
        let isBatch = !!backlog.length
        const isFeeExcited =
          !isBatch &&
          backlog.flowId.flowRulePerformLogs &&
          backlog.flowId.flowRulePerformLogs.results.find(r => r.result === 'false')
        const payMoney =
          !isBatch && (getNodeValueByPath(backlog, 'flowId.form.payMoney.standard') || 0) * 1
        if (isFeeExcited && payMoney) {
          const message = isFeeExcited?.errorMsg
          showBudgetModal({ message }, isFeeExcited, 'pay', _this, backlog, fn)
        } else {
          handlePayFlow(_this, backlog, fn, fetchParams, isByDetail, tableSource)
        }
      }
      // Bill_approval_result = ''
      break
    case 8: //打印单据
      let dataSource = backlog.flowId || backlog
      let obj = api.invokeService('@share:get:print:param', dataSource)
      let data = [obj]
      doPrint(data, false, fn)
      // Bill_approval_result = '打印单据'
      break
    case 9: //穿透查询员工所有借款包列表
      api.invokeService('@audit:get:loan:Details', backlog)
      // Bill_approval_result = '穿透查询员工所有借款包列表'
      break
    case 10: //标记已读
      const flowData = backlog.flowId
      api.invokeService('@audit:marked:read', { ids: [flowData.id] }).then(_ => {
        fn && fn()
      })
      // Bill_approval_result = '标记已读'
      break
    case 11: //转交审批
      handleTransferFlow(backlog, fn, fetchParams, riskData, 'SHIFT_NODE')
      // Bill_approval_result = '转交审批'
      break
    case 12: //确认收单
      handleReceiveBackLog(_this, backlog, fn)
      // Bill_approval_result = '确认收单'
      break
    case 13: //评论
      api.open('@bills:BillCommentModal', { flow: backlog.flowId }).then(params => {
        const args = { params, id: backlog.flowId.id }
        if (noCheckPermissions) {
          args.checkPermissions = false
        }
        api.invokeService('@bills:comment:flow', args).then(_ => {
          fn && fn()
        })
      })
      // Bill_approval_result = '评论'
      break
    case 14: //添加寄送信息
      api.open('@audit:AddExpressInfoModal').then(resp => {
        handleCheckBackLog(_this, backlog, resp, fn)
      })
      // Bill_approval_result = '添加寄送信息'
      break
    case 15: //跳过寄送信息
      api.open('@audit:JumpExpressInfoModal').then(resp => {
        handleCheckBackLog(_this, backlog, resp, fn)
      })
      // Bill_approval_result = '跳过寄送信息'
      break
    case 16: //收到打印
      handleReceivePrint([get(backlog, 'flowId.id')], fn)
      // Bill_approval_result = '收到打印'
      break
    case 17: //加签审批
      handleTransferFlow(backlog, fn, fetchParams, riskData)
      // Bill_approval_result = '加签审批'
      break
    case 18: // 单据作废
      const flowId = get(backlog, 'id')
      const node = getCurNode(backlog)
      const config = node?.config ?? {}
      const { allowApproverNullify, lockInvoiceWhenNullify } = config
      api
        .open('@audit:NulllifyBillModal', { allowApproverNullify, lockInvoiceWhenNullify, flowId })
        .then(resp => {
          api
            .dispatch(
              actions.nullifyBackLog({
                ids: [flowId],
                resubmitMethod: 'FROM_START',
                comment: '',
                params: {
                  nullifyType: resp.lockInvoiceWhenNullify ? 'lockInvoice' : 'releaseInvoice',
                },
              }),
            )
            .then(result => {
              if (result?.errors?.length !== 0) {
                showMessage.warning(result.errors[0].message || '作废失败')
              } else {
                showMessage.success(i18n.get('作废成功'))
              }
              // handleBatchResult(result, 'nullify')
              fn && fn()
            })
        })
      // Bill_approval_result = '单据作废'
      break
    case 20: //收单异常 /web/src/plugins/bills/layers/bill-info-popup/BillDetail.js
      handleReceiveException(backlog, fn)
      // Bill_approval_result = '收单异常'
      break
    case 21: //取消收单异常 /web/src/plugins/bills/layers/bill-info-popup/BillDetail.js
      handleCancelReceiveException(backlog, fn)
      // Bill_approval_result = '收单异常'
      break
  }
  Bill_approval_result &&
    newTrack('Bill_approval', {
      Bill_approval_result,
      navWeight: i18n.get('单据处理'),
      flowID: backlog.flowId.id,
      Approval_page_display_source: isEmail ? '邮件' : '非邮件',
    })
}

function getRejectData(taskId, nodes) {
  // 根据 taskId 到nodes里面找数据
  let spcSetting = false,
    spcResubmitMethod,
    id,
    rejectTo,
    rejectToNode,
    rejectToNodeIndex

  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id === taskId) {
      rejectToNode = nodes[i]
      rejectToNodeIndex = i
      break
    }
  }

  // 在nodes里面找到数据后， 在查看 rejectSetting 数据
  if (
    rejectToNode?.hasOwnProperty('rejectSetting') &&
    rejectToNode?.rejectSetting?.rejectMethod?.methods?.length > 0
  ) {
    //存在设置了单个节点的驳回配置，弹窗提示，走单个节点的驳回配置
    spcSetting = true
    rejectTo = rejectToNode?.rejectSetting?.rejectTo

    // 如果 rejectSetting 中 rejectTo 有值， 根据 rejectTo 值到整个nodes里面找 configNodeId 相同数据 （type不能为ebot）， 找到之后获取id
    if (rejectTo === null) {
      id = null
    }

    if (!id && id !== null) {
      for (let value of nodes) {
        if (rejectTo === value.configNodeId && value.type != 'ebot') {
          id = value.id
          break
        }
      }
    }

    // 根据 rejectTo 没有找到数据， 则根据当前node索引往上找最近 非 ebot数据， 找到后返回id， 没有到话 返回null
    if (!id && id !== null) {
      for (let i = rejectToNodeIndex - 1; i >= 0; i--) {
        if (nodes[i].type != 'ebot') {
          id = nodes[i].id
          break
        }
      }
    }

    if (!id && id !== null) {
      id = null
    }

    spcResubmitMethod = rejectToNode?.rejectSetting?.rejectMethod?.methods[0]
    return { spcResubmitMethod, id, spcSetting }
  }
}

function getCurNode(backlog) {
  const plan = backlog?.flowId?.plan ?? {}
  const { taskId = '', nodes = [] } = plan
  return nodes.find(node => node.id === taskId)
}

export async function handleTransferFlow(backlog, fn, fetchParams, riskData, transferType) {
  const flowPlanConfig = await actions.getBatchFlowConfig({ flowIds: get(backlog, 'flowId.id') })
  const params = await api.open('@bills:ApproveTransferModal', {
    isSignNode: !transferType,
    showAfter: backlog.flowId.state !== 'paying',
    flowData: [backlog.flowId],
    fetchParams,
    riskData,
    flowPlanConfig: flowPlanConfig.value,
  })
  api.invokeService('@bills:transfer:flow', { ...params, id: backlog.id }).then(result => {
    fn && fn()
  })
}

export function handlePrintRemind(flowIds, done) {
  const { showPrintRemindModal } = app.require('@lib/lib-util')

  showPrintRemindModal(() =>
    api.dispatch(actions.printRemindAction(flowIds)).then(res => {
      handleBatchResult(res, 'printremind')
      done && done()
    }),
  )
}

export async function handleTransfer(param) {
  const { ids, data, showAfter, fn, isSelectAll, fetchParams, isSignNode } = param
  let flowIds = getSelectedRowFlowIds(data)
  const flowPlanConfig = isSelectAll
    ? await actions.getBatchFlowConfigByConditions({ fetchParams })
    : await actions.getBatchFlowConfig({ flowIds: flowIds })
  const params = await api.open('@bills:ApproveTransferModal', {
    isSignNode,
    showAfter,
    isSelectAll,
    fetchParams,
    flowData: Object.values(data),
    flowPlanConfig: flowPlanConfig.value,
  })
  api.dispatch(actions.transferFlow({ ...params, id: ids })).then(result => {
    handleBatchResult(result, 'add-node')
    fn && fn()
  })
}

export function handleReceivePrint(ids, fn) {
  receivePrint(ids).then(() => {
    showMessage.success(i18n.get('操作成功'))
    fn && fn()
  })
}

export async function agreeBills(ids, fn, isSelectAll, flowIds, fetchParams, selectedRowData) {
  const messageCode = await api.invoke('@vendor:dd:message:code')
  const approveAction = value => {
    if (isSelectAll && fetchParams) {
      return actions.batchApproveByConditions(
        { fetchParams, action: { ...value, name: 'freeflow.agree' } },
        messageCode,
      )
    } else if (ids && ids.length) {
      let data = { ids, ...value }
      return actions.batchApproveByIds({ ...data, name: 'freeflow.agree' }, messageCode)
    }
  }
  const flowPlanConfig = isSelectAll
    ? await actions.getBatchFlowConfigByConditions({ fetchParams })
    : await actions.getBatchFlowConfig({ flowIds })
  const result = await api.open('@audit:AgreeBillModal', {
    ids,
    flowIds,
    fetchParams,
    approveAction,
    flowPlanConfig: flowPlanConfig.value,
    isSelectAll,
    isBatchAgree: true,
    selectedRowData,
  })
  const type = ids && ids.length > 1 ? 'batchAgree' : 'singleAgree'
  handleBatchResult(result, type)
  fn && fn()
}

export function handleReceiveBackLog(_this, backlog, fn) {
  actions.getBacklogFlowplan(backlog).then(plan => {
    if (judgeBillStatus(plan.value)) {
      showBillStatusChangeModal(fn)
    } else {
      api
        .open('@audit:FlowAllowModal', {
          data: {
            flowPlan: plan.value,
            staffs: _this.props.staffs,
            state: backlog.state,
            flowId: backlog.flowId,
            isAgreeFromDetail: true,
          },
        })
        .then(data => {
          let {
            node: { approverId, approverIds },
          } = data
          if (approverId || approverIds) {
            api.dispatch(
              actions.setFlowPlanNode({ ...data.node }, (state, action) => {
                !action.error && receiveBackLog(data, backlog, fn)
              }),
            )
          } else {
            receiveBackLog(data, backlog, fn)
          }
        })
    }
  })
}

export function handleReceiveException(backlog, fn) {
  const titleStyle = {
    fontSize: '17px',
  }
  const contentStyle = {
    display: 'inline-block',
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'PingFangSC-Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
  }
  showModal.confirm({
    title: <span style={titleStyle}>{i18n.get('收单异常确认')}</span>,
    content: (
      <span style={contentStyle}>
        {i18n.get(
          '点击【收单异常】这些单据的状态将变为收单异常状态，异常状态的单据该提交人将不允许提交新的相同类型的单据，你可以在列表展示里找到收单异常状态列表再次确认收单。',
        )}
      </span>
    ),
    okText: i18n.get('确定'),
    cancelText: i18n.get('取消'),
    onOk() {
      receiveExceptionBackLog(backlog, fn)
    },
  })
}

export function handleCancelReceiveException(backlog, fn) {
  const titleStyle = {
    fontSize: '17px',
  }
  const contentStyle = {
    display: 'inline-block',
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'PingFangSC-Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
  }
  showModal.confirm({
    title: <span style={titleStyle}>{i18n.get('提示')}</span>,
    content: <span style={contentStyle}>{i18n.get('确定取消收单异常？')}</span>,
    okText: i18n.get('确定'),
    cancelText: i18n.get('取消'),
    onOk() {
      cancelReceiveExceptionBackLog(backlog, fn)
    },
  })
}

function receiveBackLog(data, backlog, fn) {
  let param = {
    backlogIds: [backlog.id],
    comment: data.comment,
    attachments: data.attachments,
  }
  if (data.autographImageId) {
    param.autographImageId = data.autographImageId
  }

  api.dispatch(actions.receiveBackLogAction(param)).then(result => {
    handleBatchResult(result, 'receive')
    fn && fn(result)
  })
}

export async function handleReceiveList(keys, selectedRowData, fn) {
  const flowIds = fnGetFlowIds(selectedRowData)
  let flowPlanConfig
  if (flowIds?.length) {
    const { value } = await actions.getBatchFlowConfig({ flowIds })
    flowPlanConfig = value
  }
  api.open('@audit:AgreeBillModal', { title: i18n.get('批量收单'), flowPlanConfig }).then(value => {
    let data = { backlogIds: keys, ...value }
    api.dispatch(actions.receiveBackLogAction(data)).then(result => {
      handleBatchResult(result, 'receive')
      fn && fn(result)
    })
  })
}

function receiveExceptionBackLog(backlog, fn) {
  const param = {
    backlogIds: [backlog.id],
    comment: '收单异常',
  }
  api.dispatch(actions.receiveExceptionBackLogAction(param)).then(result => {
    handleBatchResult(result, 'receiveException')
    fn && fn(result)
  })
}

function cancelReceiveExceptionBackLog(backlog, fn) {
  const param = {
    backlogIds: [backlog.id],
    comment: '取消收单异常',
  }
  api.dispatch(actions.cancelReceiveExceptionBackLogAction(param)).then(result => {
    handleBatchResult(result, 'cancelReceiveException')
    fn && fn(result)
  })
}

export function handleReceiveExceptionList(keys, selectedRowData, fn) {
  const titleStyle = {
    fontSize: '17px',
  }
  const contentStyle = {
    display: 'inline-block',
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'PingFangSC-Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
  }
  showModal.confirm({
    title: <span style={titleStyle}>{i18n.get('批量收单异常确认')}</span>,
    content: (
      <span style={contentStyle}>
        {i18n.get(
          '点击【收单异常】这些单据的状态将变为收单异常状态，异常状态的单据该提交人将不允许提交新的相同类型的单据，你可以在列表展示里找到收单异常状态列表再次确认收单。',
        )}
      </span>
    ),
    okText: i18n.get('确定'),
    cancelText: i18n.get('取消'),
    onOk() {
      const data = { backlogIds: keys, comment: '收单异常' }
      api.dispatch(actions.receiveExceptionBackLogAction(data)).then(result => {
        handleBatchResult(result, 'receiveException')
        fn && fn(result)
      })
    },
  })
}

export function handleCancelReceiveExceptionList(keys, selectedRowData, fn) {
  const titleStyle = {
    fontSize: '17px',
  }
  const contentStyle = {
    display: 'inline-block',
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'PingFangSC-Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
  }
  showModal.confirm({
    title: <span style={titleStyle}>{i18n.get('批量取消收单异常确认')}</span>,
    content: <span style={contentStyle}>{i18n.get('确定批量取消收单异常？')}</span>,
    okText: i18n.get('确定'),
    cancelText: i18n.get('取消'),
    onOk() {
      const data = { backlogIds: keys, comment: '批量取消收单异常' }
      api.dispatch(actions.cancelReceiveExceptionBackLogAction(data)).then(result => {
        handleBatchResult(result, 'cancelReceiveException')
        fn && fn(result)
      })
    },
  })
}

export function handleAgreeBills(selectedRowKeys, selectedRowData, fn, isSelectAll, fetchParams) {
  let ids = getSelectedRowDataIds(selectedRowData)
  let flowIds = getSelectedRowFlowIds(selectedRowData)
  let _this = this
  agreeBills.call(_this, ids, fn, isSelectAll, flowIds, fetchParams, selectedRowData)
}

export function handleRejectBills(selectedRowKeys, selectedRow, fn, isByDetail) {
  const selectedRowData = selectedRow ?? {}
  let list = Object.values(selectedRowData)
  if (isByDetail) {
    list = uniqBy(list || [], 'flowId.id')
  }
  //判断有无单据设置了单个节点的驳回配置
  let spcSetting = false,
    idsComment = [],
    subData = [],
    rejectData,
    spcResubmitMethod,
    spcRejectTo

  list.map(item => {
    let nodes = item?.flowId?.plan?.nodes || []
    let taskId = item?.flowId?.plan?.taskId
    rejectData = getRejectData(taskId, nodes) || {}
    if (rejectData?.spcSetting) {
      spcSetting = true
    }
    //设置弹窗里反显的数据
    if (list.length === 1) {
      spcResubmitMethod = rejectData?.spcResubmitMethod
      spcRejectTo = rejectData?.id
    }
    //如果有配置了rejectSetting，存配置的rejectSetting的值
    if (rejectData?.spcResubmitMethod) {
      subData.push({
        id: isByDetail ? item.flowId?.id : item.id,
        rejectTo: rejectData?.id,
        resubmitMethod: rejectData?.spcResubmitMethod,
      })
    } else {
      //如果没有配置rejectSetting，先把id存起来
      if (isByDetail) {
        idsComment.push(item.flowId?.id)
      } else {
        idsComment.push(item.id)
      }
    }
  })

  api
    .open('@audit:RejectBillModal', { list, spcSetting, spcResubmitMethod, spcRejectTo })
    .then(data => {
      const { resubmitMethod, rejectTo, comment } = data
      //没有rejectSetting设置的数据，走弹窗里选择的数据
      idsComment.forEach(v => {
        subData.push({
          id: v,
          rejectTo: rejectTo,
          resubmitMethod: resubmitMethod,
        })
      })
      subData.forEach(i => {
        ;(i.comment = comment), (i.name = 'freeflow.reject')
      })
      api
        .dispatch(
          isByDetail
            ? actions.spRejectBackLogByFlow({ data: subData })
            : actions.spRejectBackLog({ data: subData }),
        )
        .then(result => {
          handleBatchResult(result)
          fn && fn()
        })
    })
}

export function handleBatchResult(result, type, isShowMessage = true) {
  //收单的时候收到成功的也会放到errors里面，因为要返回flowId，所以receive要单独处理
  let errorList = (result && (result.errors || result.errorMsg)) || []
  let sucIndex = result && result.success
  if (
    type === 'receive' ||
    type === 'receiveException' ||
    type === 'cancelReceiveException' ||
    type === 'sendExpress'
  ) {
    const list = (result.value && result.value.errors) || []
    errorList = list.filter(item => item.resultCode !== 'OK')
    sucIndex = result.value && result.value.success
  }
  let failIndex = errorList.length

  if (failIndex > 0) {
    api.open('@audit:ApprovalCompleteModal', {
      label: batchShowModalTitle(failIndex, sucIndex, type),
      details: batchShowModalContent(errorList),
    })
    /*showModal.warning({
      title: batchShowModalTitle(failIndex, sucIndex, type),
      content: batchShowModalContent(errorList),
      okText: sureMsg(),
      className: 'show-close'
    })*/
  } else {
    ;(type === 'batchAgree' || type === 'singleAgree') &&
      isShowMessage &&
      showMessage.success(i18n.get('审批成功！'))
    if (type === 'reject' || type === 'agree') {
      shouldCloseWindow()
    }
  }
}

function shouldCloseWindow() {
  const { filterFromUrl } = app.require('@lib/lib-util')
  if (filterFromUrl('shouldClose')) {
    setTimeout(() => window.close(), 1000)
  }
}
export function showBudgetModal(
  item,
  isFeeExcited,
  type,
  _this,
  backlog,
  fn,
  riskData,
  actionType,
) {
  let msgBatch = i18n.get('包括未占用任何预算或占用超额预算的单据，是否继续支付？')
  let msgType =
    type === 'agree' || type === 'addnode' ? i18n.get('是否继续审批？') : i18n.get('是否继续支付？')
  let msg = `${item?.message},${msgType}`

  showModal.confirm({
    title: i18n.get('提示'),
    content: backlog.length && type === 'pay' ? i18n.get(msgBatch) : i18n.get(msg),
    okText: continueMsg(),
    cancelText: cancelMsg(),
    onOk() {
      if (type === 'agree') {
        handleAgreeFlow(_this, backlog, fn, riskData, actionType)
      } else {
        handlePayFlow(_this, backlog, fn)
      }
    },
  })
}

export function handleAgreeFlow(_this, backlog, fn, riskData, actionType) {
  actions.getBacklogFlowplan(backlog).then(plan => {
    if (judgeBillStatus(plan.value)) {
      showBillStatusChangeModal(fn)
    } else {
      api
        .open('@audit:FlowAllowModal', {
          data: {
            flowPlan: plan.value,
            staffs: _this.props.staffs,
            riskData,
            flowId: backlog.flowId,
            isAgreeFromDetail: true,
          },
        })
        .then(data => {
          let {
            node: { approverId, approverIds },
          } = data
          if (approverId || approverIds) {
            api.dispatch(
              actions.setFlowPlanNode({ ...data.node }, (state, action) => {
                !action.error && agreeBackLog(data, backlog, fn, actionType)
              }),
            )
          } else {
            agreeBackLog(data, backlog, fn, actionType)
          }
        })
    }
  })
}

export function exportBills(flowIds = []) {
  flowIds = flowIds.join(',')
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let exportUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/flow/v2/flows/export/[${flowIds}]/$xlsx?corpId=${ekbCorpId}`
  return api.emit('@vendor:download', exportUrl)
}

export function exportBacklogs(data) {
  const flowIds = getSelectedRowFlowIds(data)
  return exportBills(flowIds)
}

export function exportAll(params, prefix = '') {
  let type = !prefix ? 'flow' : ''
  const query = parseQuery2Select(params, undefined, type)
  const filterBy = (query.value().filterBy || '').replace(/flowId\./gi, '')

  let filterStr = encodeURIComponent(filterBy)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)

  let exportAllUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/flow/v2/flows/export/${prefix}$xlsx?corpId=${ekbCorpId}&filter=${filterStr}`
  api.emit('@vendor:download', exportAllUrl)
}

export function exportAllFilterStr(params) {
  let { status } = params
  status = { state: ['archived', 'paid', 'paying', 'approving', 'sending', 'receiving'], ...status }
  params = { ...params, status }
  const query = parseQuery2Select(params, undefined, 'flow')
  const filterBy = (query.value().filterBy || '').replace(/flowId\./gi, '')
  return encodeURIComponent(filterBy)
}

export function exportAllPaying(params) {
  let { status } = params
  status = { ...status, 'backLog.state': ['PAYING'] }
  params = { ...params, status }
  return exportAll(params, 'backlog/')
}

function judgeBillStatus(plan = {}) {
  return plan.taskId === 'SUBMIT' //单据已经变成草稿了，不能进行审批动作
}

function showBillStatusChangeModal(fn) {
  showModal.error({
    title: fail(),
    content: i18n.get('该单据已被撤回或驳回，无法执行此操作'),
    okText: sureMsg(),
    onOk() {
      fn()
    },
  })
}

function openModifyBillModal(dataSource, fn, backLogOwnerId, riskData) {
  api
    .open('@bills:BillInfoModifyModal', {
      dataSource,
      backLogOwnerId,
      riskData,
    })
    .then(_ => {
      fn && fn()
    })
}

export async function rePay(backlog) {
  try {
    const res = await actions.postPayMoneyByFlow(backlog?.id)
    const { amountList, standardAmount } = fnGetPayAmount(res)
    if (standardAmount?.standard === null) {
      showMessage.warning(i18n.get('所选单据本位币不一致，不能一起发起支付，请重新勾选!'))
      return
    }
    const data = await api.open('@audit:PayMethodModal', {
      data: { backlog: [backlog], amountList },
      repay: true,
    })
    let dynamicChannelMap = {}
    if (data.dynamicChannelMap) {
      dynamicChannelMap = data.dynamicChannelMap
      delete data.dynamicChannelMap
    }
    const params = { ...data }
    const messageCode = await api.invoke('@vendor:dd:message:code')
    const payeePayPlan = get(backlog, 'flowId.form.payeePayPlan', false)
    const payPlanMode = get(backlog, 'flowId.form.payPlanMode', false)
    const details =
      payeePayPlan || payPlanMode
        ? get(backlog, 'flowId.payPlan', [])
        : get(backlog, 'flowId.details', [])
    const flowId = get(backlog, 'flowId.id', '')
    const multiplePayeesMode = get(backlog, 'flowId.form.multiplePayeesMode', false)
    let ids = []
    if (multiplePayeesMode) {
      ids =
        payeePayPlan || payPlanMode
          ? details.map(item => item.dataLinkId)
          : details.map(item => item.feeTypeForm.detailId)
    } else {
      ids = [flowId]
    }
    params.detailIds = ids
    params.flowId = flowId
    let { channel } = params
    return api
      .dispatch(payAgin(params, messageCode))
      .then(response => {
        const batch = response.value
        if (batch?.needReview) {
          showMessage.info(i18n.get('已进入复核中'))
        }
        const _this = this
        const callback = null
        const billType = 'expense'
        switch (channel) {
          case 'CHANPAY': //旧银企联走原方法... 改不动
          case 'CHANPAYV2':
            return _handleCHANPAY(batch, backlog, callback, channel)
          default:
            // 新渠道走新方法...
            return payByEveryoneChannel(
              _this,
              batch,
              callback,
              channel,
              dynamicChannelMap,
              billType,
              backlog,
            )
        }
      })
      .then(_ => {
        api.invokeService('@common:get:backlog:count:payment')
      })
  } catch (e) {
    console.log(e)
    showMessage.error(i18n.get('重新支付失败'))
  }
}

export async function payPlanRepay(payPlan, type) {
  try {
    let paymentId = []
    if (type === 'payment') {
      paymentId = payPlan?.payplanRepayIds
    } else {
      paymentId.push(payPlan?.id)
    }
    const res = await actions.postPayingPaymentMoneyByFlow(paymentId)
    const { amountList, standardAmount } = fnGetPayAmount(res)
    if (standardAmount?.standard === null) {
      showMessage.warning(i18n.get('所选单据本位币不一致，不能一起发起支付，请重新勾选!'))
      return
    }
    const data = await api.open('@audit:PayMethodModal', {
      data: { payPlan, backlog: { flowId: {} }, amountList, type },
      isPayPlanRepay: true,
    })
    let dynamicChannelMap = {}
    if (data.dynamicChannelMap) {
      dynamicChannelMap = data.dynamicChannelMap
      delete data.dynamicChannelMap
    }
    const params = { ...data }
    const messageCode = await api.invoke('@vendor:dd:message:code')

    if (paymentId && paymentId.length) {
      params.paymentIds = paymentId
      // 过滤掉不用的参数
      delete params.isUsePaymentPlan
      delete params.paymentPlans
    }
    let { channel } = params
    return api
      .dispatch(postPayPlanRepay(params, messageCode))
      .then(response => {
        const batch = response.value
        if (batch?.needReview) {
          showMessage.info(i18n.get('已进入复核中'))
        }
        const _this = this
        const callback = () => {
          api.emit('reload:pending:paying')
          api.invokeService('@common:get:backlog:count:payment')
        }
        const billType = 'expense'

        switch (channel) {
          case 'CHANPAY': //旧银企联走原方法... 改不动
          case 'CHANPAYV2':
            return _handlePayPlanCHANPAY(batch, callback, channel)
          default:
            // 新渠道走新方法...
            const flowId = get(payPlan, 'id') ? get(payPlan, 'id') : get(payPlan, 'flowId')
            return payByEveryoneChannel(
              _this,
              batch,
              callback,
              channel,
              dynamicChannelMap,
              billType,
              undefined,
              flowId,
            )
        }
      })
      .then(_ => {
        api.emit('reload:pending:paying')
        api.invokeService('@common:get:backlog:count:payment')
      })
  } catch (e) {
    console.log(e)
    showMessage.error(i18n.get('重新支付失败'))
  }
}

export function checkPaySuccess(line) {
  api.open('@audit:CheckPaySuccessModal').then(() => {
    const { id: flowId, channelTradeNo, paymentChannel } = line
    const params = {
      flowId,
      channelType: paymentChannel,
      channelTradeNo,
      status: 'SUCCESS',
    }
    _setPayState(params)
  })
}

export function checkPayFailed(line) {
  api.open('@audit:CheckPayFailedRiskModal').then(res => {
    const { id: flowId, channelTradeNo, paymentChannel } = line
    const params = {
      flowId,
      channelType: paymentChannel,
      channelTradeNo,
      status: 'FAILURE',
      ...res,
    }
    _setPayState(params)
  })
}

function _setPayState(params) {
  setPayState(params)
    .then(({ value: { result } }) => {
      if (result === 'SUCCESS') {
        showMessage.success(i18n.get('操作成功'))
        api.emit('reload:pending:paying')
      } else {
        showMessage.error(i18n.get('操作失败'))
      }
    })
    .catch(error => {
      showMessage.error(error.errorMessage || i18n.get('操作失败'))
    })
}

export function payLogView(line, type) {
  api.open('@audit:PayLogModal', { line, type })
}

export async function checkFlowAllRequiredFields(flowIds = []) {
  const ids = isArray(flowIds) ? flowIds : [flowIds]
  if (ids?.length) {
    const { items } = await checkFlowIsAllRequired({ flowIds: ids }).catch(err => {
      showMessage(err?.msg || err?.message)
      return false
    })
    if (items?.length) {
      showModal.error({
        title: <div className={'fs-16'}>{i18n.get('提示')}</div>,
        content: (
          <div className={'dis-f fd-c mt-10'}>
            <div>以下单据:</div>
            <div>{`${items.map(item => item.code).join(',')}`}</div>
            <div>有必填字段没填，请先点击修改单据补充必填字段</div>
          </div>
        ),
      })
      return false
    }
  }
  return true
}

export function fnGetFlowIds(data) {
  if (isObject(data)) {
    return Object.values(data)
      ?.map(backlog => backlog?.flowId?.id)
      .filter(id => !!id)
  }
  return []
}

export const tableService = {
  handleActionImplementation,
  handlePrint,
}

function agreePaymentReview(line, _, propsValue) {
  const data = {
    reviewId: line?.id,
    isAgree: true,
  }
  postPaymentManagementApproval(data)
    .then(_ => {
      propsValue?.bus?.emit('audit-payment:get:paymentReview:data')
    })
    .catch(err => {
      showMessage.error(i18n.get(err?.errorMessage || err?.message))
    })
}

function rejectPaymentReview(line, _, propsValue) {
  api.open('@audit:RejectPaymentReviewModal', { line }).then(() => {
    propsValue?.bus?.emit('audit-payment:get:paymentReview:data')
  })
}

export const payingTableService = {
  cancelPayment,
  confirmPayment,
  checkPayStatus,
  changePaymentOffline,
  payPlanRepay,
  checkPaySuccess,
  checkPayFailed,
  payLogView,
  agreePaymentReview,
  rejectPaymentReview,
}
