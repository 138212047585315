/************************************************
 * Created By nanyuantingfeng On 5/30/16 02:44.
 ************************************************/

export const ID = '@audit'

export default {
  ID: ID,
  ID_COMMON: '@common',
  ID_AUDIT_PENDING: `${ID}::pending`,

  GET_APPROVE_EXPENSE_BILLS: `${ID}/GET_APPROVE_EXPENSE_BILLS`,
  GET_APPROVE_LOAN_BILLS: `${ID}/GET_APPROVE_LOAN_BILLS`,
  GET_PAYMENT_EXPENSE_BILLS: `${ID}/GET_PAYMENT_EXPENSE_BILLS`,
  GET_PAYMENT_LOAN_BILLS: `${ID}/GET_PAYMENT_LOAN_BILLS`,

  GET_HISTORY_RECORDS: `${ID}/GET_HISTORY_RECORDS`,
  GET_HISTORY_BILLS: `${ID}/GET_HISTORY_BILLS`,
  GET_BILL_INFO: `${ID}/GET_BILL_INFO`,
  EXPORT_BILLS: `${ID}/EXPORT_BILLS`,

  ADD_PRINT_REMIND: `${ID}/ADD_PRINT_REMIND`,
  LOAN_BATCH_APPROVE: `${ID}/LOAN_BATCH_APPROVE`,
  GET_APPROVE_COUNT: `${ID}/GET_APPROVE_COUNT`,
  GET_ORG_EXPENSE_BILLS: `${ID}/GET_ORG_EXPENSE_BILLS`,

  //new kes
  GET_BACKLOG_LIST: `${ID}/GET_BACKLOG_LIST`,
  GET_BACKLOG_LIST_BY_ACTION: `${ID}/GET_BACKLOG_LIST_BY_ACTION`,
  GET_BACKLOG_INFO: `${ID}/GET_BACKLOG_INFO`,
  SET_FLOW_PLAN_NODE: `${ID}/SET_FLOW_PLAN_NODE`,
  AGREE_BACKLOG: `${ID}/AGREE_BACKLOG`,
  GET_RECEIVE_BACKLOG: `${ID}/GET_RECEIVE_BACKLOG`,
  LIGHTNING_RECEIVE: `${ID}/ LIGHTNING_RECEIVE`,
  RECEIVE_BACKLOG: `${ID}/RECEIVE_BACKLOG`,
  RECEIVE_EXCEPTION_BACKLOG: `${ID}/RECEIVE_EXCEPTION_BACKLOG`,
  CANCEL_RECEIVE_EXCEPTION_BACKLOG: `${ID}/CANCEL_RECEIVE_EXCEPTION_BACKLOG`,
  REJECT_BACKLOG: `${ID}/REJECT_BACKLOG`,
  PAY_BACKLOG: `${ID}/PAY_BACKLOG`,
  GET_APPROVED_FLOW_LIST: `${ID}/GET_APPROVED_FLOW_LIST`,
  APPEND_BACKLOG_LIST_BY_ACTION: `${ID}/APPEND_BACKLOG_LIST_BY_ACTION`,

  //loan
  GET_LOAN_BACKLOG_LIST: `${ID}/GET_LOAN_BACKLOG_LIST`,
  GET_LOAN_BACKLOG_INFO: `${ID}/GET_LOAN_BACKLOG_INFO`,
  GET_LOAN_PACKAGE_LIST_BY_ID: `${ID}/GET_LOAN_PACKAGE_LIST_BY_ID`,

  //search
  SEARCH_BACKLOGS: `${ID}/SEACRH_BACKLOGS`,
  GET_DIMS: `${ID}/GET_DIMS`,

  //现金罗盘新家接口
  GET_PAY_BIND_PHONE_INFO: `${ID}/GET_PAY_BIND_PHONE_INFO`,
  SET_PAY_BIND_IPHONE: `${ID}/SET_PAY_BIND_IPHONE`,
  GET_PAY_PHONE_CAPTCHA: `${ID}/GET_PAY_PHONE_CAPTCHA`,
  GET_THIRD_PHONE_CAPTCHA: `${ID}/GET_THIRD_PHONE_CAPTCHA`,
  OPEN_PAY_MESSAGE: `${ID}/OPEN_PAY_MESSAGE`,

  CONFIRM_PAY: `${ID}/CONFIRM_PAY`,
  CANCEL_PAY_BATCH: `${ID}/CANCEL_PAY_BATCH`,
  CONFIRM_PAYMENT: `${ID}/CONFIRM_PAYMENT`,
  CANCEL_PAY: `${ID}/CANCEL_PAY`,
  ZERO_PAY: `${ID}/ZERO_PAY`,

  GET_PAYMENT_BATCH: `${ID}/GET_PAYMENT_BATCH`,
  GET_PAYMENT_RECORD: `${ID}/GET_PAYMENT_RECORD`,
  APPEND_PAYMENT_RECORD: `${ID}/APPEND_PAYMENT_RECORD`,

  UPDATE_PAYMENT_STATE: `${ID}/UPDATE_PAYMENT_STATE`,
  PRINT_REMIND: `${ID}PRINT_REMIND`,
  MARKED_READ_SELECTED: `${ID}/MARKED_READ_SELECTED`,
  MARKED_READ_ALL: `${ID}/MARKED_READ_ALL`,
  GET_CARBON_COPY_FLOW_INFO_BY_ID: `${ID}/GET_CARBON_COPY_FLOW_INFO_BY_ID`,
  GET_CARBON_COPY_FLOW_INFO_BY_FLOW_ID: `${ID}/GET_CARBON_COPY_FLOW_INFO_BY_FLOW_ID`,

  TRANSFER_FLOW: `${ID}/TRANSFER_FLOW`,
  //快递
  GET_EXPRESS: `${ID}/GET_EXPRESS`,
  GET_EXPRESS_NUMS: `${ID}/GET_EXPRESS_NUMS`,

  //通过单据号找单据详情
  GET_FLOW_INFO_BY_CODE: `${ID}/GET_FLOW_INFO_BY_CODE`,
  CHECK_APPROVE_PROGRESS: `${ID}/CHECK_APPROVE_PROGRESS`,
  REPAY: `${ID}/REPAY`,
  PAYPLAN_REPAY: `${ID}/PAYPLAN_REPAY`,

  //业务对象 支付计划
  GET_DATALINK_ENTITIES: `${ID}/GET_DATALINK_ENTITIES`,
  GET_DYNAMIC_CHANNEL: `${ID}/GET_DYNAMIC_CHANNEL`,

  //转账文件
  GET_TRANSFERFILE_TEMPLATE: `${ID}/GET_TRANSFERFILE_TEMPLATE`,

  UPDATE_IMAGE_SIGN: `${ID}/UPDATE_IMAGE_SIGN`,
  SAVE_IMAGE_SIGN: `${ID}/SAVE_IMAGE_SIGN`,

  // 批量提醒
  BATCH_TIPS: `${ID}/BATCH_TIPS`,
  // 单据作废
  NULLIFY_BACKLOG: `${ID}/NULLIFY_BACKLOG`,
  // 电子回单
  GET_RECEIPT_DATALINK_ENTITIES: `${ID}/GET_RECEIPT_DATALINK_ENTITIES`,

  SET_IN_BATCH_PAYING: `${ID}/SET_IN_BATCH_PAYING`,

  //审批常用语
  GET_COMMENT_WORDS: `${ID}/GET_COMMENT_WORDS`,
  NEW_COMMENT_WORDS: `${ID}/NEW_COMMENT_WORDS`,
  EDIT_COMMENT_WORDS: `${ID}/EDIT_COMMENT_WORDS`,
  SET_APPROVAL_CONFIG: `${ID}/SET_APPROVAL_CONFIG`,
  GET_APPROVAL_CONFIG: `${ID}/GET_APPROVAL_CONFIG`,
  
  // 线下支付更新支付完成时间
  OFFLINE_FINISHED_TIME_AVAILABLE: `${ID}/OFFLINE_FINISHED_TIME_AVAILABLE`
}
