/**************************************************
 * Created by nanyuantingfeng on 27/09/2017 13:58.
 **************************************************/

export const all = [
    'title',
    'code',
    'specificationId',
    'submitDate',
    'submitterId',
    'state',
    'reviewStatus'
  ]
  
  export const expense = [
    'title',
    'code',
    'specificationId',
    'expenseDate',
    'submitterId',
    'expenseDepartment',
    'state',
    'expenseMoney'
  ]
  
  export const loan = [
    'title',
    'code',
    'specificationId',
    'loanDate',
    'submitterId',
    'loanDepartment',
    'state',
    'loanMoney'
  ]
  
  export const requisition = [
    'title',
    'code',
    'specificationId',
    'requisitionDate',
    'submitterId',
    'requisitionDepartment',
    'state',
    'requisitionMoney'
  ]
  

export const exclude = []

export const mapping = { all, expense, loan, requisition, exclude }
