import { app } from '@ekuaibao/whispered'
/**************************************************
 * Created by nanyuantingfeng on 18/09/2017 15:38.
 **************************************************/
export default [
  {
    key: 'FlowAllowModal',
    getComponent: () => Promise.resolve(app.require('@elements/flow-allow-modal')),
    width: 800,
    maskClosable: false,
    wrapClassName: '',
    footer: [],
    className: 'custom-modal-layer',
  },
  {
    key: 'PayMethodModal',
    getComponent: () => import('../elements/select-paymethod-modal'),
    title: '',
    width: 800,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal',
  },
  {
    key: 'RejectBillModal',
    getComponent: () => import('../elements/reject-bill-modal'),
    width: 800,
    maskClosable: false,
    wrapClassName: '',
    footer: [],
    className: 'custom-modal-layer',
  },
  {
    key: 'ExportModal',
    getComponent: () => import('../elements/export-modal'),
    title: i18n.get('导出设置'),
    width: 600,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal',
  },
  {
    key: 'PrintModal',
    getComponent: () => Promise.resolve(app.require('@elements/print-modal')),
  },
  {
    key: 'AgreeBillModal',
    getComponent: () => import('../elements/agree-bill-modal/agree-bill-modal'),
    width: 800,
    maskClosable: false,
    wrapClassName: '',
    footer: [],
    className: 'custom-modal-layer',
  },
  {
    key: 'InfoModal',
    getComponent: () => import('../elements/info-modal'),
  },
  {
    key: 'ZeroPayModal',
    getComponent: () => import('../elements/zero-pay-modal'),
  },
  {
    key: 'PayingBindPhone',
    getComponent: () => import('../elements/paying-bind-phone'),
    width: 600,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal',
  },
  {
    key: 'ExpressNumberModal',
    getComponent: () => import('../receiveExpress/modal/ExpressNumberModal'),
    wrapClassName: 'vertical-center-modal',
    maskClosable: false,
    width: 600,
  },
  {
    key: 'AddExpressInfoModal',
    getComponent: () => import('../sendExpress/modal/AddExpressInfoModal'),
    width: 600,
    height: 344,
    maskClosable: false,
    wrapClassName: '',
  },
  {
    key: 'JumpExpressInfoModal',
    getComponent: () => import('../sendExpress/modal/JumpExpressInfo'),
    width: 584,
    height: 242,
    maskClosable: false,
    wrapClassName: '',
  },
  {
    key: 'DownloadSheetsModal',
    getComponent: () => import('../elements/download-sheets-modal'),
    width: 584,
    style: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  {
    key: 'SelectPaymentTypeModal',
    getComponent: () => import('../elements/select-refresh-type'),
  },
  {
    key: 'ApprovalCompleteModal',
    getComponent: () => import('../elements/agree-bill-modal/approval-complete-modal'),
    title: '',
    width: 600,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal',
  },
  {
    key: 'RepaymentModal',
    getComponent: () => import('../elements/repayment-modal'),
    width: 768,
    maskClosable: false,
  },
  {
    key: 'TransferVouchersSelectModal',
    getComponent: () => import('../elements/TransferVouchersModal/TransferVouchersSelectModal'),
    width: 600,
    maskClosable: false,
  },
  {
    key: 'TransferVouchersEditModal',
    getComponent: () => import('../elements/TransferVouchersModal/TransferVouchersEditModal'),
    width: 800,
    maskClosable: false,
  },
  {
    key: 'NulllifyBillModal',
    getComponent: () => import('../elements/nullify-bill-modal'),
    width: 400,
    maskClosable: false,
  },
  {
    key: 'CommonAccountModal',
    enhancer: 'modal',
    enhancerOptions: {
      title: '',
      footer: [],
      className: 'custom-modal-layer',
    },
    getComponent: () => import('../elements/CommonAccountModal'),
    width: 800,
  },
  {
    key: 'ReceiptBindPaymentPlanModal',
    width: 800,
    enhancer: 'modal',
    enhancerOptions: {
      title: '',
      footer: [],
      className: 'respond-modal-layer',
    },
    maskClosable: false,
    getComponent: () => import('../page-menu/paid'),
  },
  {
    key: 'AdjustPaymentPlan',
    enhancer: 'drawer',
    enhancerOptions: {
      title: '',
      footer: [],
      placement: 'bottom',
      className: 'custom-drawer-layer',
      closable: false,
      width: '100%',
      height: 'calc(100% - 48px)',
    },
    getComponent: () => import('../elements/AdjustPaymentPlan'),
  },
  {
    key: 'HSBC_SupplyPaymentDrawer',
    enhancer: 'drawer',
    enhancerOptions: {
      title: '',
      footer: [],
      placement: 'bottom',
      className: 'custom-drawer-layer',
      closable: false,
      width: '100%',
      height: 'calc(100% - 48px)',
    },
    getComponent: () => import('../elements/HSBC_SupplyPaymentDrawer'),
  },
  {
    key: 'AdjustPaymentPlanInner',
    enhancer: 'modal',
    enhancerOptions: {
      title: '',
      footer: [],
      className: 'custom-modal-layer custom-modal-layer-for-select-modal',
      closable: false,
      width: 600,
    },
    getComponent: () => import('../elements/AdjustPaymentPlan/components/Layer'),
  },
  {
    key: 'CheckPaySuccessModal',
    getComponent: () => import('../elements/checkPaySuccessModal'),
    width: 400,
    maskClosable: false,
    wrapClassName: '',
  },
  {
    key: 'CheckPayFailedRiskModal',
    getComponent: () => import('../elements/checkPayFailedRiskModal'),
    width: 600,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'PayLogModal',
    getComponent: () => import('../elements/PayLogModal/PayLogModal'),
    width: 770,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'PayTipsModal',
    getComponent: () => import('../elements/payTipsModal'),
    width: 480,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'AutoMergerPlanModel',
    getComponent: () =>
      import('../elements/AdjustPaymentPlan/components/Layer/AutoMergerPlanModel'),
    width: 800,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'HSBC_SupportFileModal',
    getComponent: () => import('../elements/HSBC_SupplyPaymentDrawer/modal/HSBC_SupportFileModal'),
    width: 480,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'HSBC_TableFillingModal',
    getComponent: () => import('../elements/HSBC_TableFillingModal'),
    width: '700',
    timeout: 500,
  },
  {
    key: 'HSBC_InstructionFileDownloadModal',
    getComponent: () =>
      import('../elements/HSBC_SupplyPaymentDrawer/modal/HSBC_InstructionFileDownloadModal'),
    width: 480,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'HSBC_SupportFileIntroductionsModal',
    getComponent: () =>
      import('../elements/HSBC_SupplyPaymentDrawer/modal/HSBC_SupportFileIntroductionsModal'),
    width: 480,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'PreviewByIframe',
    getComponent: () => import('../page-menu/previewIframe'),
    width: 800,
    // maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'ReExchangeModal',
    getComponent: () => import('../elements/ReExchangeModal/index'),
    width: 1100,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'PayPlanModal',
    getComponent: () => import('../elements/ReExchangeModal/payPlanModal'),
    width: 800,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'upLoadFiles',
    getComponent: () => import('../elements/ReExchangeModal/upLoad'),
    width: 500,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'BrowModal',
    getComponent: () => Promise.resolve(app.require('@elements/BrowModal')),
    width: 400,
    maskClosable: false
  },
  {
	key: 'CheckPayMethodStatusModal',
    getComponent: () => import('../elements/checkPayMethodStatusModal'),
    width: 424,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
  {
    key: 'RejectPaymentReviewModal',
    getComponent: () => import('../elements/rejectPaymentReviewModal'),
    width: 800,
    maskClosable: false,
    wrapClassName: 'vertical-center-modal select-member-modal',
  },
]
