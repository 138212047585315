// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import { handlePrint } from './service-print'
import { fetchApproved } from './util/fetchUtil'
import {
  searchApprovedFlowsCalcNoPage,
  searchBackLogs,
  getPaymentBatch,
  setFlowPlanNode,
  getDynamicChannel,
  getBackLogInfo,
  getFlowInfo,
  markedReadALL,
  markedReadSelect,
  getCarbonCopyFlowInfoById,
  getLoanPackageListByID,
  getTransferfileTemplate,
  getVersionsFlowplan,
  updateImageSign,
  saveImageSign,
  handleBatchTips,
  getBatchFlowConfig,
  getExpressNums,
  getExpress,
  supplementExpressNum,
  editExpressNum,
  getCarbonCopyFlowInfoByFlowId,
  searchApprovedFlowsSimpleCalcNoPage,
  getCommentWords,
  newCommentWords,
  editCommentWords,
  postAllPaymentManagementBatch,
  postPaymentManagementBatch,
  postPaymentManagementReviewRecord,
} from './audit-action'
import { moneyGroupData, RiskStatus } from './elements/agree-bill-modal/AgreeBillUtil'
import { renderPayDetail, getLoanRisk } from './view-util-index-ref'
import loadable from '@loadable/component'
import { handleActionImplementation } from './service'

export const Universal_Unique_Key = 'audit.pc'

export default [
  {
    id: '@audit',
    reducer: () => import('./audit-reducer'),

    'list:backlog'() {
      api.dispatch(searchBackLogs({ start: 0, count: 1, state: 'APPROVING', type: 'expense' }))
      api.dispatch(searchBackLogs({ start: 0, count: 1, state: 'PAYING', type: 'expense' }))
      api.dispatch(searchBackLogs({ start: 0, count: 1, state: 'APPROVING', type: 'loan' }))
      api.dispatch(searchBackLogs({ start: 0, count: 1, state: 'PAYING', type: 'loan' }))
      api.dispatch(getPaymentBatch({ start: 0, count: 0, filter: 'active==true' }))
    },

    'get:dynamic:channel': params => api.dispatch(getDynamicChannel()),
    'set:plan-node': params => api.dispatch(setFlowPlanNode(params)),
    'get:transferfile:template': () => api.dispatch(getTransferfileTemplate()),

    'get:backlog-info': async params => {
      const action = await getBackLogInfo(params)
      return api.dispatch(action).then(resp => {
        let backlog = resp.value
        if (backlog.flow) {
          backlog.flowId = backlog.flow
        }
        return backlog
      })
    },
    'get:history-flow:info': async params => {
      const action = await getFlowInfo(params)
      return api.dispatch(action)
    },

    'handle:multipeprint': (keys, selectedData, done) => handlePrint(keys, selectedData, done),

    'handle:batchTips': (keys, data) => handleBatchTips(keys, data),

    'marked:read': data => {
      let { type, ids } = data
      if (type === 'marked_read_all') {
        return api.dispatch(markedReadALL())
      }
      return api.dispatch(markedReadSelect(ids))
    },

    'get:carbonCopy': async params => {
      const action = await getCarbonCopyFlowInfoById(params)
      return api.dispatch(action)
    },

    'get:carbonCopy:by:flowId': async params => {
      const action = await getCarbonCopyFlowInfoByFlowId(params)
      return api.dispatch(action)
    },

    'get:loan:Details': (line, privilegeId, showAllFeeTypeInManage) => {
      let params = {}
      let data = line.flowId || line
      let owner = data.form.submitterId
      params.ownerId = owner.id
      params.flowId = data.id
      params.state = 'REPAID'
      params.start = 0
      params.count = 999
      if (privilegeId) {
        params.privilegeId = privilegeId
      }
      api.dispatch(getLoanPackageListByID(params)).then(resp => {
        api.open('@bills:BillStackerModal', {
          viewKey: 'LoanPackageListView',
          showAllFeeTypeInManage,
          loanList: resp.items,
          owner,
          hiddenButton: true,
          flowId: data.id,
        })
      })
    },

    // approve:handleActionImplementation 没有被使用, 删除
    'approve:handleActionImplementation': params => {
      const { type, backlog, callback, riskData, actionType, context, fetchParams } = params
      debugger
      handleActionImplementation.call(
        null,
        type,
        backlog,
        callback,
        riskData,
        actionType,
        context,
        fetchParams,
      )
    },

    getPaymentBatchPromise: (data = {}) => {
      const param = {
        start: 0,
        count: 2999,
        order: 'asc(createTime)',
        searchValue: data?.searchValue ?? '',
      }
      return api.dispatch(getPaymentBatch(param))
    },
    getPaymentBatch: (data = {}) => {
      const param = {
        start: 0,
        count: 2999,
        order: 'asc(createTime)',
        searchValue: data?.searchValue ?? '',
      }
      api
        .dispatch(getPaymentBatch(param))
        .then(_ => api.invokeService('@layout5:refresh:menu:data'))
    },
    'invoke:fetchApproved': (params, scene, dimensionItems) => {
      return fetchApproved(params, scene, dimensionItems)
    },
    'invoke:searchApprovedFlowsCalcNoPage': (
      params,
      scene,
      dimensionItems,
      legalEntityCurrencyPower,
    ) => {
      return searchApprovedFlowsCalcNoPage(params, scene, dimensionItems, legalEntityCurrencyPower)
    },
    'invoke:searchApprovedSimpleFlowsCalcNoPage': (
      params,
      scene,
      dimensionItems,
      legalEntityCurrencyPower,
    ) => {
      return searchApprovedFlowsSimpleCalcNoPage(
        params,
        scene,
        dimensionItems,
        legalEntityCurrencyPower,
      )
    },
    'import:moneyGroupData': () => moneyGroupData,
    'import:renderPayDetail': () => renderPayDetail,
    'import:getLoanRisk': () => getLoanRisk,
    'import:RiskStatus': () => RiskStatus,
    'paying-bind-phone': () => import('./elements/paying-bind-phone'),
    getVersionFlowPlanById: async params => {
      const result = await getVersionsFlowplan(params)
      return result.value
    },
    'update:signature': (param = {}) => {
      const { id, imageId, use, lastHabit } = param
      if (id) {
        return api.dispatch(updateImageSign(param))
      } else {
        return api.dispatch(saveImageSign({ imageId, use, lastHabit }))
      }
    },
    'get:batch:flow:Config': (param = {}) => {
      return getBatchFlowConfig(param)
    },
    'get:express:nums': (param = {}) => {
      return api.dispatch(getExpressNums(param))
    },
    'get:express': (param = {}) => {
      return api.dispatch(getExpress(param))
    },
    'supplement:express:num': (param = {}) => {
      return api.dispatch(supplementExpressNum(param))
    },
    'edit:express:num': param => {
      return api.dispatch(editExpressNum(param))
    },
    'get:commentwords': param => {
      return api.dispatch(getCommentWords(param))
    },
    'new:commentwords': param => {
      return api.dispatch(newCommentWords(param))
    },
    'edit:commentwords': param => {
      return api.dispatch(editCommentWords(param))
    },
    'get:all:payment:management:batch': (data = {}) => {
      const param = {
        start: 0,
        count: 2999,
        order: 'asc(createTime)',
        searchValue: data?.searchValue ?? '',
      }
      return postAllPaymentManagementBatch(param)
    },
    'get:payment:management:batch': (data = {}) => {
      const param = {
        start: 0,
        count: 2999,
        order: 'asc(createTime)',
        searchValue: data?.searchValue ?? '',
      }
      return postPaymentManagementBatch(param)
    },
    'get:payment:management:reviewRecord': (data = {}) => {
      const param = {
        limit: { start: 0, count: 2999 },
        filterBy: data?.filterBy ?? {}
      }
      return postPaymentManagementReviewRecord(param)
    },
  },
  {
    path: '/audit/approve',
    ref: '/',
    onload: () => import('./audit.approve'),
  },
  {
    path: '/audit/payment',
    ref: '/',
    onload: () => import('./audit.payment'),
  },
  {
    path: '/audit/tobepaid',
    ref: '/',
    onload: () => import('./page-menu/toBePaid'),
  },
  {
    path: '/audit/payments',
    ref: '/',
    onload: () => import('./page-menu/payments'),
  },
  {
    path: '/audit/paid',
    ref: '/',
    onload: () => import('./page-menu/paid'),
  },
  {
    path: '/audit/paymentRecord',
    ref: '/',
    onload: () => import('./page-menu/paymentRecord'),
  },
  {
    path: '/audit/paymentFailed',
    ref: '/',
    onload: () => import('./page-menu/paymentFailed'),
  },
  {
    path: '/audit/reexchange',
    ref: '/',
    onload: () => import('./page-menu/reexchange'),
  },
  {
    path: '/audit/receipt',
    ref: '/',
    onload: () => import('./page-menu/receipt'),
  },
  {
    path: '/audit/carbonCopy',
    ref: '/',
    onload: () => import('./audit.carbonCopy'),
  },
  {
    path: '/audit/paymentSetting',
    ref: '/',
    onload: () => import('../src/paymentManagement/PaymentSetting'),
  },
  {
    path: '/audit/paymentReview',
    ref: '/',
    onload: () => import('../src/paymentManagement/PaymentReview'),
  },
  {
    point: '@@layers',
    prefix: '@audit',
    onload: () => require('./layers').default,
  },
  {
    path: '/audit/receiveExpress',
    ref: '/',
    onload: () => import('./AuditReceive'),
    // onload: () => import('./receiveExpress/ReceiveExpressView')
  },
  {
    path: '/wait-send-service',
    ref: '/',
    onload: () => import('./AuditSend'),
    // onload: () => import('./sendExpress/express-view/express.view.container')
  },
  {
    point: '@@menus',
    onload: () => [
      { id: 'audit', weight: 1, label: '待办' },
      { id: 'audit-pending', pId: 'audit', weight: 1, label: '待审批', href: '/audit/approve' },
      { id: 'audit-payment', pId: 'audit', weight: 4, label: '待支付', href: '/audit/payment' },
      {
        id: 'audit-carbonCopy',
        pId: 'audit',
        weight: 5,
        label: '抄送我的',
        href: '/audit/carbonCopy',
      },
      {
        id: 'receive-express',
        pId: 'audit',
        weight: 3,
        label: '待收单',
        href: '/audit/receiveExpress',
      },
      {
        id: 'wait-send-service',
        pId: 'audit',
        weight: 2,
        label: '待寄送',
        href: '/wait-send-service',
      },
    ],
  },
  {
    path: '/backlogDetail/:isBacklog/:id',
    ref: '/',
    onload: () => import('./BacklogDetail'),
    dependencies: ['@auth-check'],
  },
  {
    resource: '@audit',
    value: {
      ['service']: loadable(() => import('./service')),
      ['service-print']: require('./service-print'),
      ['service-renderOpenLinkDesc']: require('./service-renderOpenLinkDesc'),
      ['WarningAlert']: loadable(() => import('./elements/agree-bill-modal/Warning-alert')),
      ['receiveExpress/ExpressListWrapper']: loadable(() =>
        import('./receiveExpress/ExpressListWrapper'),
      ),
      ['elements/agree-bill-modal/AgreeBillUtil']: require('./elements/agree-bill-modal/AgreeBillUtil'),
      ['services/ApproveService']: require('./services/ApproveService'),
      ['util/mapping4paid']: require('./util/mapping4paid'),
      ['util/Utils']: require('./util/Utils'),
      ['CaptchaModal']: loadable(() => import('./elements/CaptchaModal')),
      ['captcha-modal-actions']: require('./elements/CaptchaModal/actions/fetch'),
      ['util/columnsAndSwitcherUtil']: require('./util/columnsAndSwitcherUtil'),
      ['BacklogDetail']: loadable(() => import('./BacklogDetail')),
      ['CommentWords']: loadable(() => import('./elements/CommentWords/CommentWords')),
    },
  },
]
