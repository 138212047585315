import {
  searchBackLogsCore,
  searchPayPlanBackLogs,
  searchApprovedFlowsCore,
  searchBackLogsCarbonCopy,
  searchPayPlanInfo,
  searchPayPlanRecordInfo,
  searchReceipt,
} from '../audit-action'
import { showMessage } from '@ekuaibao/show-util'
import { Resource, Fetch } from '@ekuaibao/fetch'

const payment = new Resource('/api/pay/v1')
const print = new Resource('/api/v1/print')
const channel = new Resource('/api/pay/v2/channel')
const transferfile = new Resource('/api/pay/v2/transferfile')
const common = new Resource('/api/pay/v2/accounts/common')
export const sencesFilter = new Resource('/api/flow/v2/filter')
const payPlanSencesFilter = new Resource('/api/pay/paymentPlan/filter')
const supplyFileForHSBC = new Resource('/api/pay/v1/plugin/processor')
const additionLogs = new Resource('/api/pay/v1/plugin/additionLogs')

const accounts = new Resource('/api/pay/v2/accounts')
const receipt = new Resource('/api/pay/v1/receipt')
const paymentBatches = new Resource('/api/pay/v2/paymentBatches')

import { app as api } from '@ekuaibao/whispered'
import { trackAuditFistTime, trackAuditNextTime } from '../util/trackAudit'

export function trackBtnClick(name, nameStr) {
  window.TRACK &&
    window.TRACK(name, {
      corpId: Fetch.ekbCorpId,
      actionName: nameStr,
    })
}

export function fetchChannel(accountSort) {
  return channel.GET('/$accountSort', { accountSort })
}
export function trackApproved(time, name, nameStr) {
  window.TRACK &&
    window.TRACK(name, {
      actionName: i18n.get(nameStr),
      corpId: Fetch.ekbCorpId,
      payingUseTime: time,
    })
}
export function fetchBackLogs(params, scene, dimensionItems, showPermit = false) {
  const start = new Date().getTime()
  return searchBackLogsCore(params, scene, dimensionItems, showPermit)
    .then(data => {
      const end = new Date().getTime()
      const t = end - start
      trackApproved(t, 'backlogTime', '待支付和部分支付以及支付中')
      const { count, items } = data
      trackAuditFistTime(params)
      trackAuditNextTime(params)
      return { dataSource: items, total: count }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}
export function fetchPayPlanBackLogs(params, scene, dimensionItems, showPermit = false) {
  const start = new Date().getTime()
  return searchPayPlanBackLogs(params, scene, dimensionItems, showPermit)
    .then(data => {
      const end = new Date().getTime()
      const t = end - start
      trackApproved(t, 'payPlanTime', '待支付明细维度')
      const { count, items } = data
      trackAuditFistTime(params)
      trackAuditNextTime(params)
      return { dataSource: items, total: count }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

export function exportPayPlanExcel(ids, state) {
  ids = encodeURIComponent(`[${ids.join(',')}]`)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  state = encodeURIComponent(state)
  const exportUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/pay/v2/paymentPlans/${ids}/export/excel/byState/$${state}?corpId=${ekbCorpId}`
  console.log('exportUrl:', exportUrl)
  api.emit('@vendor:download', exportUrl)
}

export function exportPaymentBatchesExcel(id, state) {
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  state = encodeURIComponent(state)
  const exportUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/pay/v2/paymentBatches/$${id}/export/excel/byState/$${state}?corpId=${ekbCorpId}`
  console.log('exportUrl:', exportUrl)
  api.emit('@vendor:download', exportUrl)
}

// 导出回单数据
export function exportReceiptExcel(ids, callback) {
  ids = encodeURIComponent(`[${ids.join(',')}]`)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const exportUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/pay/v1/receipt/export/${ids}?corpId=${ekbCorpId}`
  console.log('exportUrl:', exportUrl)
  api.emit('@vendor:download', exportUrl)
  typeof callback === 'function' && callback()
}

// 重新获取回单文件
export async function getReceipt(ids, callback) {
  const res = await receipt.GET('/get/[ids]', { ids })
  typeof callback === 'function' && callback(res)
}

// 获取回单数据绑定支付计划的id
export function getReceiptBindedPlanId(id) {
  return receipt.GET(`/getReceipt/${encodeURIComponent(id)}`)
}

// 下载回单文件
export async function downloadReceipt(ids, callback) {
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  if (!Array.isArray(ids)) {
    const fileUrl = `${Fetch.fixOrigin(
      location.origin,
    )}/api/pay/v1/receipt/getfile?fileKey=${ids}&corpId=${ekbCorpId}`
    api.emit('@vendor:download', fileUrl)
    typeof callback === 'function' && callback()
    return
  }
  ids = ids.filter(id => id)
  if (ids.length <= 0) {
    return showMessage.error(i18n.get('渠道未生成回单文件，请先重新获取文件！'))
  } else if (ids.length > 20) {
    return api
      .open('@audit:PrintModal', {
        okText: i18n.get('download'),
        modalTitle: i18n.get('下载任务生成'),
        handleSubmit: data => {
          receipt.GET(`/async/$type/[ids]?taskName=${data.taskName}`, { ids, type: 'download' })
        },
      })
      .then(_ => {
        showMessage.success(i18n.get('创建成功'))
      }) // 大于20条，异步下载
  }
  ids = encodeURIComponent(`[${ids.join(',')}]`)
  const downloadUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/pay/v1/receipt/download/${ids}?corpId=${ekbCorpId}`
  console.log('downloadUrl:', downloadUrl)
  api.emit('@vendor:download', downloadUrl)
  typeof callback === 'function' && callback()
}

// 打印回单文件
export function printReceipt(ids, callback) {
  ids = ids.filter(id => id)
  if (ids.length <= 0) {
    return showMessage.error(i18n.get('渠道未生成回单文件，请先重新获取文件！'))
  } else if (ids.length > 20) {
    return api
      .open('@audit:PrintModal', {
        handleSubmit: data => {
          receipt.GET(`/async/$type/[ids]?taskName=${data.taskName}`, { ids, type: 'print' })
        },
      })
      .then(_ => {
        showMessage.success(i18n.get('创建成功'))
      }) // 大于20条，异步打印
  }
  ids = encodeURIComponent(`[${ids.join(',')}]`)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const printUrl = `${Fetch.fixOrigin(
    location.origin,
  )}/api/pay/v1/receipt/print/${ids}?corpId=${ekbCorpId}&accessToken=${Fetch.accessToken}`
  api.emit('@vendor:open:link', printUrl, false)
  typeof callback === 'function' && callback()
}

//待支付 支付失败  支付成功
export function searchReceiptByPage(params, state) {
  return receipt.POST('/reexchange/searchByPage', params, state)
}

//待支付 支付失败  支付成功
export function fetchReceipt(params, state, cb) {
  return searchReceiptByPage(params, state)
    .then(data => {
      const result = data.items
      cb && cb(result)
      return { dataSource: result.data, total: result.total }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

//待支付 支付失败  支付成功
export function fetchPayPlanInfo(params, state, cb) {
  return searchPayPlanInfo(params, state)
    .then(data => {
      const result = data.items
      cb && cb(result)
      return { dataSource: result.data, total: result.total }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

//支付记录
export function fetchPayPlanRecordInfo(query, params, from) {
  return searchPayPlanRecordInfo(query, params, from)
    .then(data => {
      return { ...data.items }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

//支付中
export function fetchPayPlanProcessingInfo(params, state) {
  return fnGetPaymentBatch(params, state)
    .then(data => {
      return data
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

export function fnGetPaymentBatch(param, state) {
  return paymentBatches.POST('/all', param, state)
}

export function fetchApproved(params, scene, dimensionItems) {
  const startTime = new Date().getTime()
  return searchApprovedFlowsCore(params, scene, dimensionItems)
    .then(data => {
      const end = new Date().getTime()
      const t = end - startTime
      trackApproved(t, 'approvedTime', '已支付')
      const { count, items } = data
      trackAuditFistTime(params)
      trackAuditNextTime(params)
      return { dataSource: items, total: count }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

export function fetchCarbonCopyBills(params, scene, dimensionItems) {
  return searchBackLogsCarbonCopy(params, scene, dimensionItems)
    .then(data => {
      const { count, items } = data
      trackAuditFistTime(params)
      trackAuditNextTime(params)
      return { dataSource: items, total: count }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

export function refreshSOUCHEPayment(data = [], channel = 'SOUCHE') {
  const params = {
    channel,
  }
  const batchId = {}
  data.forEach(item => {
    batchId[item] = item
  })
  params.batchId = batchId
  return payment.PUT('/$channel/updateStateBatch', { ...params }).then(result => {
    return result.value
  })
}

export function refreshDoubleSOUCHEPayment(data = [], channels = []) {
  const requests = []
  channels.forEach(channel => {
    const params = {
      channel,
    }
    const batchId = {}
    data.forEach(item => {
      if (item.channel === channel) {
        batchId[item.id] = item.id
      }
    })
    params.batchId = batchId
    requests.push(
      payment.PUT('/$channel/updateStateBatch', { ...params }).then(result => {
        return result.value
      }),
    )
  })
  return Promise.all(requests)
    .then(res => {
      const result1 = res[0] || {}
      const result2 = res[1] || {}
      console.log(result1)
      console.log(result2)
      const msg = `${result1.msg} ${result2.msg}`
      const service_resp_desc = `${result1.service_resp_desc || ''}, ${
        result2.service_resp_desc || ''
      }`
      return { msg, service_resp_desc }
    })
    .catch(err => {
      console.log(err)
      return { msg: i18n.get('刷新失败'), service_resp_desc: i18n.get('刷新失败') }
    })
}

export function changePaymentToOffline(data) {
  return payment.PUT('/payments/$id/payByOffline', data)
}

export function receivePrint(ids) {
  return print.PUT('/receive/$[ids]', { ids })
}

function getTemplate(res, fn) {
  return api.invokeService('@audit:get:transferfile:template').then(() => fn && fn(res))
}

//创建转账模板
export function createTransferfileTemplate(params, fn) {
  return transferfile
    .POST('/template', params)
    .then(res => {
      showMessage.success(i18n.get('创建成功'))
      getTemplate(res, fn)
    })
    .catch(err => showMessage.error(err))
}

//删除转账模板
export function deleteTransferfileTemplate(id, fn) {
  return transferfile
    .DELETE('/template/$id', { id })
    .then(res => {
      showMessage.success(i18n.get('删除成功'))
      getTemplate(res, fn)
    })
    .catch(err => showMessage.error(err))
}

//修改转账模板
export function putTransferfileTemplate(id, param, fn) {
  return transferfile
    .PUT('/template/$id', { ...param, id })
    .then(res => {
      showMessage.success(i18n.get('保存成功'))
      getTemplate(res, fn)
    })
    .catch(err => showMessage.error(err))
}

//获取常用付款账户配置页面数据列表
export function fetchCommon() {
  return common.GET('/$DESKTOP', { DESKTOP: 'DESKTOP' })
}

export function saveCommonConfig(params) {
  return common.POST('', params)
}

// 获取表格列相关数据
export function getScenes(scenesType) {
  return sencesFilter.GET('/$type', { type: scenesType })
}

// 获取表格列相关数据
export function saveScenes(params) {
  return sencesFilter.POST('/$type', params)
}

// 获取支付计划表格列相关数据
export function getPayPlanScenes(scenesType) {
  return payPlanSencesFilter.GET('/$type', { type: scenesType })
}

// 保存支付计划表格列相关数据
export function savePayPlanScenes(params) {
  return payPlanSencesFilter.POST('/$type', params)
}

// 汇丰银行：保存BOP等补充文件数据
export function saveSupplyFileForHSBC(params) {
  return supplyFileForHSBC.POST('/$type', { type: 'HSBCPAY', ...params })
}

// 汇丰银行：保存生成文件相关操作的日志
export function saveLogForHSBC(params) {
  return additionLogs.POST('/$channel/$ekbTradeNo/$channelTradeNo', params)
}

export function fetchReceiptSearch(params, state, cb) {
  return searchReceipt(params, state)
    .then(data => {
      let result = data.items
      result.data = result.data.map((v, index) => ({
        ...v,
        serialNumber: params?.limit?.start + index + 1,
      })) // 加一个序号
      console.log(result, 'result')
      cb && cb(result)
      return { dataSource: result.data, total: result.total }
    })
    .catch(e => {
      showMessage.error(e)
      return { dataSource: [], total: 0 }
    })
}

export function fetchAccounts(params) {
  return accounts.GET('/receipt', params)
}

export function bindPaymentPlan(data) {
  return receipt.POST('/reexchange/handleBind', data)
}

export function unbindPaymentPlan(id) {
  return receipt.GET(`/reexchange/unbind/${id}`)
}

export function getTransferfileFields() {
  return transferfile.POST('/template/fields')
}

export function getTransferfileHeaders(data) {
  return transferfile.POST('/file/headers', data)
}
