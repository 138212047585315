import { app as api } from '@ekuaibao/whispered'
import { dealPrintData } from '@ekuaibao/lib/lib/lib-util'
import { showMessage } from '@ekuaibao/show-util'

export function handlePrint(selectedRowKeys, selectedRowData, done, sortByIndex = false) {
  let data = dealPrintData(selectedRowKeys, selectedRowData)
  if (selectedRowData.showAllFeeType) data.showAllFeeType = true
  doPrint(data, true, done, sortByIndex)
}

export function doPrint(data, withPrivilegeId, done, sortByIndex = false) {
  let { privilegeId = '', showAllFeeType, isTablePreview } = data
  let selectKeys = []
  if (withPrivilegeId) {
    selectKeys = data.map(line => line.flowId.concat(':').concat(line.privilegeId ? line.privilegeId : privilegeId))
  } else {
    selectKeys = data.map(obj => obj.flowId)
  }

  let invoiceSumSize = 0
  let isContainerPrintInvoice =
    data.filter(s => {
      invoiceSumSize += s.invoicesSize
      return s.isPrintInvoice
    }).length > 0
  let isAnsy = (isContainerPrintInvoice && invoiceSumSize > 5) || data.length > 20
  if (isAnsy) {
    api
      .open('@audit:PrintModal', {
        done: done,
        data: data,
        withPrivilegeId: withPrivilegeId,
        selectedRowKeys: selectKeys,
        privilegeId: data.privilegeId,
        showAllFeeType,
        sortByIndex
      })
      .then(_ => {
        showMessage.success(i18n.get('创建成功'))
      })
  } else {
    api.invokeService('@share:print:list', {
      isTablePreview,
      flowId: selectKeys,
      withPrivilegeId: withPrivilegeId,
      isAsyn: isAnsy,
      callback: done,
      privilegeId: data.privilegeId,
      showAllFeeType,
      sortByIndex
    })
  }
}
