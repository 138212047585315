import React from 'react'
import { app as api } from '@ekuaibao/whispered'
import { getLoanPackageListByID } from './audit-action'
import moment from 'moment'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'

export function getDiffDays(repayment) {
  let repaymentTime = moment(repayment)
  let days = moment(repaymentTime).diff(moment(), 'days', true)
  return days
}

export function getExpenseRiskTip(resp, backlog) {
  let riskTip = ''
  let writtenOff = backlog.writtenOff || (backlog.flowId && backlog.flowId.writtenOff)
  if (resp.items.length) {
    resp.items.every(item => {
      if (!writtenOff && item.remain > 0) {
        riskTip = window.IS_SZJL? i18n.get('有可核销的借款或预付款尚未核销') : i18n.get('有可核销借款但未核销')
        return false
      }
      return true
    })
    if (!riskTip) {
      resp.items.every(item => {
        if (item.repaymentDate) {
          //有还款时间
          let diffDays = getDiffDays(item.repaymentDate)
          if (diffDays < 0) {
            riskTip = i18n.get('有借款已超期')
            return false
          } else if (diffDays >= 0 && diffDays <= 7) {
            riskTip = i18n.get('有借款即将到期')
            return false
          }
        }
        return true
      })
    }
  }
  return riskTip
}

export function getLoanRiskTip(resp, backlog) {
  let riskTip = ''
  if (resp.items.length > 0) {
    resp.items.every(item => {
      if (item.repaymentDate > 0) {
        //有还款时间
        let diffDays = getDiffDays(item.repaymentDate)
        if (diffDays < 0) {
          riskTip = i18n.get('有借款已超期')
          return false
        } else if (diffDays >= 0 && diffDays <= 7) {
          riskTip = i18n.get(' 有借款即将到期')
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    })
  }

  return riskTip
}

export function getLoanRisk(backlog) {
  const submitterId = getNodeValueByPath(backlog, 'flowId.form.submitterId', {})
  const configs = getNodeValueByPath(backlog, 'configs', [])
  const chargeAgainst = configs.find(item => item.ability === 'chargeAgainst')
  if (!!chargeAgainst && chargeAgainst.isChargeAgainst) {
    return Promise.resolve()
  }
  let param = {
    ownerId: submitterId.id,
    flowId: backlog.flowId.id,
    state: 'REPAID',
    start: 0,
    count: 999
  }
  return new Promise((resolve, reject) => {
    if (backlog.type === 'loan' || backlog.type === 'expense') {
      return api.dispatch(getLoanPackageListByID(param)).then(resp => {
        let name = submitterId.name
        let tip = backlog.type === 'loan' ? getLoanRiskTip(resp) : getExpenseRiskTip(resp, backlog)
        tip = tip && name + ' ' + tip
        resolve(tip)
      })
    } else {
      resolve()
    }
  })
}

export function renderPayDetail(doc = {}) {
  return (
    <div className="ekb-account-info-popover">
      <div className="header">
        {doc.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户')} | {doc.accountName || doc.name}
      </div>
      <div className="body">
        <div className="line1">
          <img className="stand-20-icon" src={doc.icon} />
          {doc.bank || doc.unionBank}
        </div>
        <div className="line2 text-nowrap-ellipsis">{doc.accountNo || doc.cardNo}</div>
        <div className="line3">{doc.branch}</div>
      </div>
    </div>
  )
}
