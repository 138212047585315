import { Resource } from '@ekuaibao/fetch'

const paymentsCaptcha = new Resource('/api/pay/v1/payments/captcha');

export const enumSmsCheck = {
    PAY: 'PAY',
    QUERY: 'QUERY',
    GRANT: 'GRANT',
    REVIEW: 'REVIEW'
}

export const getPaymentsCaptcha = (params) => {

    return paymentsCaptcha.POST('', params)
}

//检查短信验证码缓存  resp: true:已有缓存,不需要再次验证 false:缓存过期,需要再次验证
export const checkCaptcha = () => {

    return paymentsCaptcha.GET()
}

